import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv"; // For exporting CSV
import { Button, Dropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import ClientAndSupplierModal from "./ClientAndSupplierModal";

const ViewClientAndSupplier = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false); // Modal mode

  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const handleShowModal = (id, editMode) => {
    console.log(id);
    setSelectedId(id);
    setIsEditMode(editMode);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  useEffect(() => {
    fetchClientAndSuppliers();
    console.log(selectedId);
  }, []);

  const fetchClientAndSuppliers = async () => {
    try {
      const response = await axiosInstance.get("/api/client-suppliers/");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching client and supplier data", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter((item) => {
    return (
      item.english_name.toLowerCase().includes(search.toLowerCase()) ||
      item.type.toLowerCase().includes(search.toLowerCase()) ||
      item.email.toLowerCase().includes(search.toLowerCase()) ||
      item.phone.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Client / Supplier List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>
                            15
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={data}
                        filename={"client-supplier-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    {/* Add Client and Supplier Button */}
                    <Button
                      variant="primary"
                      onClick={() => navigate("/addClientAndSupplier")}
                      className="float-right"
                    >
                      Add Client / Supplier
                    </Button>
                  </div>

                  {/* Table */}
                  <table className="table table-striped text-center">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((item) => (
                        <tr key={item.id}>
                          <td>{item.english_name}</td>
                          <td>{item.type}</td>
                          <td>{item.email}</td>
                          <td>{item.phone}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, false)}
                            >
                              View Details
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, true)}
                            >
                              Update
                            </Button>
                            {/* Removed Delete Button */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredData.length === 0 && (
                    <p className="text-center">
                      No client or supplier available.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ClientAndSupplierModal
        show={showModal}
        handleClose={handleCloseModal}
        clientAndSupplierData={selectedId}
        isEditMode={isEditMode}
      />
    </div>
  );
};

export default ViewClientAndSupplier;
