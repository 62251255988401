import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const CategoryModal = ({ show, handleClose, categoryData, isEditMode, onSave }) => {
  const [category, setCategory] = useState({
    id: '',
    code: '',
    english_name: '',
    arabic_name: '',
    type: '',
    active: ''
  });

  useEffect(() => {
    if (categoryData) {
      setCategory(categoryData);
      console.log(category.active);
    }
  }, [categoryData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  const handleSave = () => {
    onSave(category);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Update Category' : 'View Category'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="categoryCode">
                <Form.Label>Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="code"
                    value={category.code}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{category.code}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="categoryType">
                <Form.Label>Type</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="select"
                    name="type"
                    value={category.type}
                    onChange={handleChange}
                  >
                    <option value="">Select Type</option>
                    <option value="purchase">Purchase</option>
                    <option value="sale">Sale</option>
                  </Form.Control>
                ) : (
                  <p>{category.type}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="categoryEnglishName">
                <Form.Label>English Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="english_name"
                    value={category.english_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{category.english_name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="categoryArabicName">
                <Form.Label>Arabic Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="arabic_name"
                    value={category.arabic_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{category.arabic_name}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="active">
                <Form.Label>Status</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="select"
                    name="active"
                    value={category.active}
                    onChange={handleChange}
                  >
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Form.Control>
                ) : (
                  <p>{category.active == 'true' ? "Active":"Inactive"}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {isEditMode && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CategoryModal;
