import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance"; // Adjust the import path as needed
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import { Button, Dropdown, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import BillModal from "./BillModal"; // Import the modal component
import AddBtn from "../../ui-kit/TableActionBtns/AddBtn";
import CsvExportBtn from "../../ui-kit/TableActionBtns/CSVExportBtn";
const BillView = () => {
  const [bills, setBills] = useState([]);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("view");
  const [selectedBill, setSelectedBill] = useState(null);
  const userId = localStorage.getItem("user_id");
  const storedCompanyId = localStorage.getItem("company_id");
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Fetch bills data
    const fetchBills = async () => {
      try {
        const response = await axiosInstance.get("/api/bills/", {
          params: { user: userId, company: storedCompanyId },
        });
        setBills(response.data);
      } catch (error) {
        console.error("Error fetching bills", error);
      }
    };

    fetchBills();
  }, [storedCompanyId]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS", bills);
  const filteredData = bills.filter(
    (item) =>
      item.supplier?.toLowerCase().includes(search.toLowerCase()) ||
      item.supplier_bill_reference
        ?.toLowerCase()
        .includes(search.toLowerCase()) ||
      item.issue_date?.toLowerCase().includes(search.toLowerCase()) ||
      item.due_date?.toLowerCase().includes(search.toLowerCase()) ||
      item.delivery_date?.toLowerCase().includes(search.toLowerCase())
  );

  const handleAddClick = () => {
    navigate("/add-bills"); // Navigate to the add-bills page
  };

  const handleEditClick = (bill) => {
    setModalMode("edit");
    setSelectedBill(bill);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedBill(null);
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Bills List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows: {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>
                            15
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* <CSVLink
                        className="btn btn-primary mx-3"
                        data={filteredData}
                        filename={"bills-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink> */}

                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div>
                      <CsvExportBtn
                        data={filteredData}
                        filename={"bills-list.csv"}
                      />
                      <AddBtn
                        onClick={handleAddClick}
                        className="float-right"
                      />
                    </div>

                    {/* <Button
                      variant="primary"
                      onClick={handleAddClick}
                      className="float-right"
                    >
                      Add Bill
                    </Button> */}
                  </div>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Supplier</th>
                        <th>Supplier Bill Reference</th>
                        <th>Issue Date</th>
                        <th>Due Date</th>
                        <th>Delivery Date</th>
                        <th>Purchase Order</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((bill, index) => (
                        <tr key={bill.id}>
                          <td>{index + 1}</td>
                          <td>{bill.supplier}</td>
                          <td>{bill.supplier_bill_reference}</td>
                          <td>{bill.issue_date}</td>
                          <td>{bill.due_date}</td>
                          <td>{bill.delivery_date}</td>
                          <td>{bill.purchase_order}</td>
                          <td>
                            <Button
                              variant="info"
                              onClick={() => handleEditClick(bill)}
                            >
                              View / Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {filteredData.length === 0 && (
                    <p className="text-center">No bills available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <BillModal
        show={modalShow}
        handleClose={handleModalClose}
        bill={selectedBill}
        mode={modalMode}
      />
    </div>
  );
};

export default BillView;
