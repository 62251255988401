import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // Import useParams
import AdminSidebar from '../master-layout/AdminSidebar';
import '../assets/css/style.css'; // Import your custom CSS
import '../assets/css/CompanyProfile.css'; // Add your CSS styles here
import axiosInstance from '../utils/axiosInstance';

const CompanyProfile = () => {
  const { companyId } = useParams(); // Get companyId from URL
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get('/api/company/${companyId}/');
        setProfile(response.data);
      } catch (error) {
        setError(error);
        console.error('Error fetching profile data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (companyId) { // Ensure companyId is defined before making the request
      fetchProfile();
    }
  }, [companyId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading profile data</div>;

  return (
    <div id="main-content" className="main-content">
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
              <li className="breadcrumb-item">Users</li>
              <li className="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>
        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img src={profile.image || '../assets/img/profile-img.jpg'} alt="Profile" className="rounded-circle" />
                  <h2>{profile.fullName}</h2>
                  <h3>{profile.jobTitle}</h3>
                  <div className="social-links mt-2">
                    <a href={profile.twitter} className="twitter"><i className="bi bi-twitter"></i></a>
                    <a href={profile.facebook} className="facebook"><i className="bi bi-facebook"></i></a>
                    <a href={profile.instagram} className="instagram"><i className="bi bi-instagram"></i></a>
                    <a href={profile.linkedin} className="linkedin"><i className="bi bi-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card">
                <div className="card-body pt-3">
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    <li className="nav-item">
                      <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit">Edit Profile</button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Settings</button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">Change Password</button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div className="tab-pane fade show active profile-overview" id="profile-overview">
                      <h5 className="card-title">About</h5>
                      <p className="small fst-italic">{profile.about}</p>
                      <h5 className="card-title">Profile Details</h5>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Full Name</div>
                        <div className="col-lg-9 col-md-8">{profile.fullName}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Company</div>
                        <div className="col-lg-9 col-md-8">{profile.companyName}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Job</div>
                        <div className="col-lg-9 col-md-8">{profile.jobTitle}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Country</div>
                        <div className="col-lg-9 col-md-8">{profile.country}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Address</div>
                        <div className="col-lg-9 col-md-8">{profile.address}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Phone</div>
                        <div className="col-lg-9 col-md-8">{profile.phone}</div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-4 label">Email</div>
                        <div className="col-lg-9 col-md-8">{profile.email}</div>
                      </div>
                    </div>
                    {/* Add other tabs (Edit Profile, Settings, Change Password) here */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default CompanyProfile;
