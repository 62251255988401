import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const RawMaterialModal = ({ show, handleClose, rawMaterialData, isEditMode, onSave }) => {
  const [rawMaterial, setRawMaterial] = useState({
    id: '',
    code: '',
    english_name: '',
    arabic_name: '',
    unit: '',
    opening_balance: '',
    opening_balance_total_price: '',
    minimum_quantity: ''
  });

  useEffect(() => {
    console.log('Raw Material Data:', rawMaterialData); // Debugging statement
    if (rawMaterialData) {
      setRawMaterial(rawMaterialData);
    }
  }, [rawMaterialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRawMaterial({ ...rawMaterial, [name]: value });
  };

  const handleSave = () => {
    console.log('Saving Raw Material:', rawMaterial); // Debugging statement
    onSave(rawMaterial);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Update Raw Material' : 'View Raw Material'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="rawMaterialCode">
                <Form.Label>Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="code"
                    value={rawMaterial.code}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.code}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="rawMaterialUnit">
                <Form.Label>Unit</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="select"
                    name="unit"
                    value={rawMaterial.unit}
                    onChange={handleChange}
                  >
                    <option value="">Select Unit</option>
                    <option value="meter">Meter</option>
                    <option value="kilo">Kilo</option>
                    {/* Add more units if necessary */}
                  </Form.Control>
                ) : (
                  <p>{rawMaterial.unit}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="rawMaterialEnglishName">
                <Form.Label>English Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="english_name"
                    value={rawMaterial.english_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.english_name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="rawMaterialArabicName">
                <Form.Label>Arabic Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="arabic_name"
                    value={rawMaterial.arabic_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.arabic_name}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="rawMaterialOpeningBalance">
                <Form.Label>Opening Balance</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="opening_balance"
                    value={rawMaterial.opening_balance}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.opening_balance}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="rawMaterialOpeningBalanceTotalPrice">
                <Form.Label>Opening Balance Total Price</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="opening_balance_total_price"
                    value={rawMaterial.opening_balance_total_price}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.opening_balance_total_price}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="rawMaterialMinimumQuantity">
                <Form.Label>Minimum Quantity</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    step="0.01"
                    name="minimum_quantity"
                    value={rawMaterial.minimum_quantity}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{rawMaterial.minimum_quantity}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {isEditMode && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default RawMaterialModal;
