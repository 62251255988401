// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PlansPage from "./components/pages/PlansPage";
import CompanyRegistration from "./components/pages/CompanyRegistration"; // Updated pat
import CompanyProfile from "./components/pages/CompanyProfile"; // Updated pat
import Dashboard from "./components/pages/Dashboard"; // Updated pat
import AdminDashboard from "./components/pages/AdminDashboard "; // Updated pat
import Company from "./components/pages/Company";
import Plans from "./components/pages/Plans";
import AddCategory from "./components/pages/catalog/AddCategory";
import ViewCategories from "./components/pages/catalog/ViewCategories";
import AddProductService from "./components/pages/catalog/AddProductService";
import ViewProductsServices from "./components/pages/catalog/ViewProductsServices";
import Login from "./components/pages/Login"; // Updated pat
import ProtectedRoute from "../src/components/ProtectedRoute ";
import AddWarehouse from "./components/pages/inventory/AddWarehouse";
import WarehouseView from "./components/pages/inventory/WarehouseView";
import ViewRowMaterials from "./components/pages/catalog/ViewRawMaterials"; // Updated pat
import AddRawMaterial from "./components/pages/catalog/AddRawMaterial ";
import AddClientAndSupplier from "./components/pages/clientSupport/AddClientAndSupplier";
import ViewClientAndSupplier from "./components/pages/clientSupport/ViewClientAndSupplier";
import InventoryListView from "./components/pages/inventory/InventoryListView"; // Updated pat
import ViewAccount from "./components/pages/accounting/ViewAccount"; // Updated pat
import AddAccount from "./components/pages/accounting/AddAccount"; // Updated pat
import ViewBankAccount from "./components/pages/accounting/ViewBankAccount"; //
import ViewBankTransfer from "./components/pages/accounting/ViewBankTransfer";
import AccountsPayableList from "./components/pages/accounting/AccountsPayableList";
import AccountsReceivableList from "./components/pages/accounting/AccountsReceivableList";
import BillView from "./components/pages/bills/BillView";
import AddBill from "./components/pages/bills/AddBill";
import BillDebitView from "./components/pages/bills/viewBillsDebit";
import AddBillDebit from "./components/pages/bills/addBillDebit";
import BillCreditView from "./components/pages/bills/viewBillsCredit";
import AddBillCredit from "./components/pages/bills/addBillCredit";
import ViewPurchaseOrder from "./components/pages/bills/ViewPurchaseOrder";
import POSInvoices from "./components/pages/pos/POSInvoices";
import POSOrders from "./components/pages/pos/POSOrders";
import ViewPOSOrder from "./components/pages/pos/ViewPOSOrder";
import ViewPOSInvoice from "./components/pages/pos/ViewPOSInvoice";
import ViewInvoicesCredit from "./components/pages/sales/viewInvoicesCredit";
import ViewInvoicesDebit from "./components/pages/sales/viewInvoicesDebit";
import InvoicesView from "./components/pages/sales/InvoiceView";
import AddInvoice from "./components/pages/sales/AddInvoice";
import AddInvoiceCredit from "./components/pages/sales/addInvoiceCredit";
import AddInvoiceDebit from "./components/pages/sales/addInvoiceDebit";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/plans"
          element={
            <ProtectedRoute>
              <Plans />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company"
          element={
            <ProtectedRoute>
              <Company />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewCategories"
          element={
            <ProtectedRoute>
              <ViewCategories />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AddRawMaterial"
          element={
            <ProtectedRoute>
              <AddRawMaterial />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewRowMaterials"
          element={
            <ProtectedRoute>
              <ViewRowMaterials />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addClientAndSupplier"
          element={
            <ProtectedRoute>
              <AddClientAndSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewClientAndSupplier"
          element={
            <ProtectedRoute>
              <ViewClientAndSupplier />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addps"
          element={
            <ProtectedRoute>
              <AddProductService />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewps"
          element={
            <ProtectedRoute>
              <ViewProductsServices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addcategory"
          element={
            <ProtectedRoute>
              <AddCategory />
            </ProtectedRoute>
          }
        />

        <Route
          path="/inventory"
          element={
            <ProtectedRoute>
              <InventoryListView />
            </ProtectedRoute>
          }
        />
        {/* WarehouseView */}
        <Route
          path="/WarehouseView"
          element={
            <ProtectedRoute>
              <WarehouseView />
            </ProtectedRoute>
          }
        />
        {/* AddWarehouse */}
        <Route
          path="/addWarehouseView"
          element={
            <ProtectedRoute>
              <AddWarehouse />
            </ProtectedRoute>
          }
        />

        <Route
          path="/chartOfAccounts"
          element={
            <ProtectedRoute>
              <ViewAccount />
            </ProtectedRoute>
          }
        />

        <Route
          path="/add-account"
          element={
            <ProtectedRoute>
              <AddAccount />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bank-account"
          element={
            <ProtectedRoute>
              <ViewBankAccount />
            </ProtectedRoute>
          }
        />

        {/* ViewBankTransfer */}
        <Route
          path="/transfer-account"
          element={
            <ProtectedRoute>
              <ViewBankTransfer />
            </ProtectedRoute>
          }
        />

        <Route
          path="/account-payable"
          element={
            <ProtectedRoute>
              <AccountsPayableList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/AccountsReceivableList"
          element={
            <ProtectedRoute>
              <AccountsReceivableList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/bills"
          element={
            <ProtectedRoute>
              <BillView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-bills"
          element={
            <ProtectedRoute>
              <AddBill />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchases"
          element={
            <ProtectedRoute>
              <ViewPurchaseOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/billsDebitNotes"
          element={
            <ProtectedRoute>
              <BillDebitView />
            </ProtectedRoute>
          }
        />
        <Route
        // mode/:billId?"
          path="/addBillDebitNote"
          element={
            <ProtectedRoute>
              <AddBillDebit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/billsCreditNotes"
          element={
            <ProtectedRoute>
              <BillCreditView />
            </ProtectedRoute>
          }
        />
        {/* :mode/:billId? */}
        <Route
          path="/addBillCreditNote/"
          element={
            <ProtectedRoute>
              <AddBillCredit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/POSOrders"
          element={
            <ProtectedRoute>
              <POSOrders />
            </ProtectedRoute>
          }
        />
        <Route
          path="/POSInvoices"
          element={
            <ProtectedRoute>
              <POSInvoices />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewPOSOrder/:posOrderId"
          element={
            <ProtectedRoute>
              <ViewPOSOrder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewPOSInvoice/:posInvoiceId"
          element={
            <ProtectedRoute>
              <ViewPOSInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewInvoicesCredit"
          element={
            <ProtectedRoute>
              <ViewInvoicesCredit />
            </ProtectedRoute>
          }
        />

        <Route
          path="/ViewInvoicesDebit"
          element={
            <ProtectedRoute>
              <ViewInvoicesDebit />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Invoices"
          element={
            <ProtectedRoute>
              <InvoicesView />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addInvoice"
          element={
            <ProtectedRoute>
              <AddInvoice />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addInvoiceCreditNote"
          element={
            <ProtectedRoute>
              <AddInvoiceCredit />
            </ProtectedRoute>
          }
        />

        <Route
          path="/addInvoiceDebitNote"
          element={
            <ProtectedRoute>
              <AddInvoiceDebit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pages-register"
          element={
            <ProtectedRoute>
              <CompanyRegistration />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:companyId"
          element={
            <ProtectedRoute>
              <CompanyProfile />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
