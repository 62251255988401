import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { Button, Dropdown, Table } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import AccountsReceivableModal from './AccountsReceivableModal'; // Import the modal component

const AccountsReceivableList = () => {
  const [accountsReceivable, setAccountsReceivable] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState('create');
  const [selectedReceivable, setSelectedReceivable] = useState(null);
  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch accounts receivable data
    const fetchAccountsReceivable = async () => {
      try {
        const response = await axiosInstance.get('/api/accounts-receivable/', {
          params: { company: storedCompanyId }
        });
        setAccountsReceivable(response.data);
      } catch (error) {
        console.error('Error fetching accounts receivable', error);
      }
    };

    fetchAccountsReceivable();
  }, [storedCompanyId]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = accountsReceivable.filter(item => 
    item.client.toLowerCase().includes(search.toLowerCase()) ||
    item.amount.toString().includes(search.toLowerCase())
  );

  const handleAddClick = () => {
    setModalMode('create');
    setSelectedReceivable(null);
    setModalShow(true);
  };

  const handleEditClick = (receivable) => {
    setModalMode('edit');
    setSelectedReceivable(receivable);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedReceivable(null);
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Accounts Receivable List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows: {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={filteredData}
                        filename={"accounts-receivable-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <Button variant="primary" onClick={handleAddClick} className="float-right">
                      Add Accounts Receivable
                    </Button>
                  </div>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>Amount</th>
                        <th>Due Date</th>
                        <th>Description</th>
                        <th>Paid</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((receivable, index) => (
                        <tr key={receivable.id}>
                          <td>{index + 1}</td>
                          <td>{receivable.client}</td>
                          <td>{receivable.amount}</td>
                          <td>{receivable.due_date}</td>
                          <td>{receivable.description}</td>
                          <td>{receivable.paid ? 'Yes' : 'No'}</td>
                          <td>
                            <Button variant="info" onClick={() => handleEditClick(receivable)}>
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {filteredData.length === 0 && (
                    <p className="text-center">No accounts receivable available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <AccountsReceivableModal
        show={modalShow}
        handleClose={handleModalClose}
        accountsReceivable={selectedReceivable}
        mode={modalMode}
      />
    </div>
  );
};

export default AccountsReceivableList;
