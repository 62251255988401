import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const AccountsReceivableModal = ({ show, handleClose, accountsReceivable, mode }) => {
  const [formData, setFormData] = useState({
    client: '',
    amount: '',
    due_date: '',
    description: '',
    paid: false,
  });
  const [clients, setClients] = useState([]);
  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch client data
    const fetchClients = async () => {
      try {
        const response = await axiosInstance.get('/api/clients/', {
          params: { company: storedCompanyId }
        });
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients', error);
      }
    };

    fetchClients();

    if (mode === 'edit' && accountsReceivable) {
      setFormData({
        client: accountsReceivable.client,
        amount: accountsReceivable.amount,
        due_date: accountsReceivable.due_date,
        description: accountsReceivable.description,
        paid: accountsReceivable.paid,
      });
    }
  }, [accountsReceivable, mode, storedCompanyId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (mode === 'create') {
        await axiosInstance.post('/api/accounts-receivable/', formData, {
          params: { company: storedCompanyId }
        });
      } else if (mode === 'edit' && accountsReceivable) {
        await axiosInstance.put(`/api/accounts-receivable/${accountsReceivable.id}/`, formData, {
          params: { company: storedCompanyId }
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error saving accounts receivable', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ top: '100px' }}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Add Accounts Receivable' : 'Edit Accounts Receivable'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formClient">
            <Form.Label>Client</Form.Label>
            <Form.Control
              as="select"
              name="client"
              value={formData.client}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDueDate">
            <Form.Label>Due Date</Form.Label>
            <Form.Control
              type="date"
              name="due_date"
              value={formData.due_date}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formPaid">
            <Form.Label>Paid</Form.Label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="paid"
                checked={formData.paid}
                onChange={handleInputChange}
              />
              <label className="form-check-label">
                {formData.paid ? 'Yes' : 'No'}
              </label>
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            {mode === 'create' ? 'Add' : 'Update'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AccountsReceivableModal;
