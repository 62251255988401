import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const BankTransferModal = ({ show, handleClose, bankTransfer, mode }) => {
  const [fromBank, setFromBank] = useState('');
  const [toBank, setToBank] = useState('');
  const [amount, setAmount] = useState(0.0);
  const [transferDate, setTransferDate] = useState('');
  const [description, setDescription] = useState('');
  const [bankAccounts, setBankAccounts] = useState([]);
  const storedUserId = localStorage.getItem('user_id');

  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch the list of bank accounts when the component mounts
    const fetchBankAccounts = async () => {
      try {
        const response = await axiosInstance.get('/api/bank-accounts/', {
          params: { user: storedUserId,company: storedCompanyId }
        });
        setBankAccounts(response.data);
      } catch (error) {
        console.error('Error fetching bank accounts:', error);
      }
    };

    fetchBankAccounts();

    // Set form fields if editing an existing bank transfer
    if (bankTransfer && mode === 'edit') {
      setFromBank(bankTransfer.from_bank);
      setToBank(bankTransfer.to_bank);
      setAmount(bankTransfer.amount);
      setTransferDate(bankTransfer.transfer_date);
      setDescription(bankTransfer.description);
    } else {
      resetForm();
    }
  }, [bankTransfer, mode]);

  const resetForm = () => {
    setFromBank('');
    setToBank('');
    setAmount(0.0);
    setTransferDate('');
    setDescription('');
  };

  const handleSave = async () => {
    try {
      const payload = {
        company: storedCompanyId,
        from_bank: fromBank,
        to_bank: toBank,
        amount: amount,
        transfer_date: transferDate,
        description: description
      };

      if (mode === 'edit' && bankTransfer) {
        await axiosInstance.put(`/api/bank-transfers/${bankTransfer.id}/`, payload, {
            params: { user: storedUserId,company: storedCompanyId }
          });
      } else {
        await axiosInstance.post('/api/bank-transfers/', payload, {
            params: { user: storedUserId,company: storedCompanyId }
          });
      }

      handleClose();
    } catch (error) {
      console.error('Error saving bank transfer:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'edit' ? 'Edit Bank Transfer' : 'Add Bank Transfer'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="fromBank">
            <Form.Label>From Bank</Form.Label>
            <Form.Control
              as="select"
              value={fromBank}
              onChange={(e) => setFromBank(e.target.value)}
            >
              <option value="">Select from bank</option>
              {bankAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="toBank" className="mt-3">
            <Form.Label>To Bank</Form.Label>
            <Form.Control
              as="select"
              value={toBank}
              onChange={(e) => setToBank(e.target.value)}
            >
              <option value="">Select to bank</option>
              {bankAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="amount" className="mt-3">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter amount"
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
            />
          </Form.Group>

          <Form.Group controlId="transferDate" className="mt-3">
            <Form.Label>Transfer Date</Form.Label>
            <Form.Control
              type="date"
              value={transferDate}
              onChange={(e) => setTransferDate(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="description" className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {mode === 'edit' ? 'Update' : 'Add'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BankTransferModal;
