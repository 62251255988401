import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup, FormControl } from 'react-bootstrap';

const ProductServiceModal = ({ show, handleClose, productServiceData, isEditMode, onSave }) => {
  const [productService, setProductService] = useState({
    id: '',
    code: '',
    english_name: '',
    arabic_name: '',
    image: null,
    purchase_price: '',
    purchase_account: '',
    sale_price: '',
    sale_account: '',
    category: '',
    status: '',
    type: 'product',
    has_raw_materials: false,
    raw_materials: [],
  });

  useEffect(() => {
    if (productServiceData) {
      setProductService(productServiceData);
    }
  }, [productServiceData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProductService(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleFileChange = (e) => {
    setProductService(prevState => ({
      ...prevState,
      image: e.target.files[0]
    }));
  };

  const handleSave = () => {
    // Create a FormData object to handle file uploads
    const formData = new FormData();
    for (const key in productService) {
      formData.append(key, productService[key]);
    }
    onSave(formData); // Pass the formData object to the parent component
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Update Product/Service' : 'View Product/Service'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group as={Row} controlId="formCode">
            <Form.Label column sm={2}>Code</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="code"
                value={productService.code}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formEnglishName">
            <Form.Label column sm={2}>English Name</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="english_name"
                value={productService.english_name}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formArabicName">
            <Form.Label column sm={2}>Arabic Name</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="arabic_name"
                value={productService.arabic_name}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formImage">
            <Form.Label column sm={2}>Image</Form.Label>
            <Col sm={10}>
              <InputGroup>
                <FormControl
                  type="file"
                  name="image"
                  onChange={handleFileChange}
                  disabled={!isEditMode}
                />
                {productService.image && !isEditMode && (
                  <InputGroup.Text>
                    <img
                      src={URL.createObjectURL(productService.image)}
                      alt="preview"
                      style={{ maxWidth: '100px', maxHeight: '100px' }}
                    />
                  </InputGroup.Text>
                )}
              </InputGroup>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPurchasePrice">
            <Form.Label column sm={2}>Purchase Price</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                name="purchase_price"
                value={productService.purchase_price}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPurchaseAccount">
            <Form.Label column sm={2}>Purchase Account</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="purchase_account"
                value={productService.purchase_account}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formSalePrice">
            <Form.Label column sm={2}>Sale Price</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="number"
                name="sale_price"
                value={productService.sale_price}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formSaleAccount">
            <Form.Label column sm={2}>Sale Account</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="sale_account"
                value={productService.sale_account}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formCategory">
            <Form.Label column sm={2}>Category</Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="category"
                value={productService.category}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formStatus">
            <Form.Label column sm={2}>Status</Form.Label>
            <Col sm={10}>
              <Form.Check
                type="checkbox"
                name="status"
                checked={productService.status}
                onChange={handleChange}
                disabled={!isEditMode}
                label={productService.status ? 'Active' : 'Inactive'}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formType">
            <Form.Label column sm={2}>Type</Form.Label>
            <Col sm={10}>
              <Form.Check
                type="radio"
                name="type"
                value="product"
                checked={productService.type === 'product'}
                onChange={handleChange}
                disabled={!isEditMode}
                label="Product"
              />
              <Form.Check
                type="radio"
                name="type"
                value="service"
                checked={productService.type === 'service'}
                onChange={handleChange}
                disabled={!isEditMode}
                label="Service"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHasRawMaterials">
            <Form.Label column sm={2}>Has Raw Materials</Form.Label>
            <Col sm={10}>
              <Form.Check
                type="checkbox"
                name="has_raw_materials"
                checked={productService.has_raw_materials}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            </Col>
          </Form.Group>
          {productService.has_raw_materials && (
            <Form.Group as={Row} controlId="formRawMaterials">
              <Form.Label column sm={2}>Raw Materials</Form.Label>
              <Col sm={10}>
                {/* Display raw materials as needed */}
                <ul>
                  {productService.raw_materials.map((material, index) => (
                    <li key={index}>
                      {/* Adjust as needed */}
                      {material.name}
                    </li>
                  ))}
                </ul>
              </Col>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      {isEditMode && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ProductServiceModal;
