import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';

const AddClientAndSupplier = () => {
  const [englishName, setEnglishName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('active');
  const [type, setType] = useState('client');
  const [taxNumber, setTaxNumber] = useState('');
  const [taxPercent, setTaxPercent] = useState('');

  const [billingContactName, setBillingContactName] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('');

  const [shippingAsBilling, setShippingAsBilling] = useState(false);
  const [shippingContactName, setShippingContactName] = useState('');
  const [shippingPhone, setShippingPhone] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [shippingCity, setShippingCity] = useState('');
  const [shippingZipCode, setShippingZipCode] = useState('');
  const [shippingState, setShippingState] = useState('');
  const [shippingCountry, setShippingCountry] = useState('');

  const userId = localStorage.getItem('user_id');
  const companyId = localStorage.getItem('company_id');

  const handleSubmit = (event) => {
    event.preventDefault();

    // Set shipping details based on billing details if shippingAsBilling is true
    const shippingDetails = shippingAsBilling ? {
      shipping_contact_name: billingContactName,
      shipping_phone: billingPhone,
      shipping_address: billingAddress,
      shipping_city: billingCity,
      shipping_zip_code: billingZipCode,
      shipping_state: billingState,
      shipping_country: billingCountry,
    } : {
      shipping_contact_name: shippingContactName,
      shipping_phone: shippingPhone,
      shipping_address: shippingAddress,
      shipping_city: shippingCity,
      shipping_zip_code: shippingZipCode,
      shipping_state: shippingState,
      shipping_country: shippingCountry,
    };

    // Prepare form data
    const formData = {
      english_name: englishName,
      arabic_name: arabicName,
      email,
      phone,
      status,
      type,
      tax_number: taxNumber,
      tax_percent: taxPercent,
      contact_name: billingContactName,
      billing_phone: billingPhone,
      billing_address: billingAddress,
      billing_city: billingCity,
      billing_zip_code: billingZipCode,
      billing_state: billingState,
      billing_country: billingCountry,
      ...shippingDetails, // Spread shipping details based on the checkbox value
      user: userId,
      company: companyId,
    };

    // Post the data using axios
    axiosInstance.post('/api/client-suppliers/', formData)
      .then((response) => {
        console.log('Client/Supplier added successfully', response.data);
        // Handle success, e.g., redirect or show a message
      })
      .catch((error) => {
        console.error('Error adding client/supplier', error);
        // Handle error
      });
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="englishName" className="form-label">English Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="englishName"
                          value={englishName}
                          onChange={(e) => setEnglishName(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="arabicName" className="form-label">Arabic Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="arabicName"
                          value={arabicName}
                          onChange={(e) => setArabicName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="status" className="form-label">Status</label>
                        <select
                          className="form-select"
                          id="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="type" className="form-label">Type</label>
                        <select
                          className="form-select"
                          id="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="client">Client</option>
                          <option value="supplier">Supplier</option>
                        </select>
                      </div>
                    </div>
                    {type === 'supplier' && (
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="taxNumber" className="form-label">Tax Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="taxNumber"
                            value={taxNumber}
                            onChange={(e) => setTaxNumber(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="taxPercent" className="form-label">Tax Percent</label>
                          <input
                            type="number"
                            step="0.01"
                            className="form-control"
                            id="taxPercent"
                            value={taxPercent}
                            onChange={(e) => setTaxPercent(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <fieldset className="mb-3">
                      <legend>Billing Details</legend>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="billingContactName" className="form-label">Contact Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingContactName"
                            value={billingContactName}
                            onChange={(e) => setBillingContactName(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="billingPhone" className="form-label">Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingPhone"
                            value={billingPhone}
                            onChange={(e) => setBillingPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="billingAddress" className="form-label">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingAddress"
                            value={billingAddress}
                            onChange={(e) => setBillingAddress(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="billingCity" className="form-label">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingCity"
                            value={billingCity}
                            onChange={(e) => setBillingCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="billingZipCode" className="form-label">Zip Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingZipCode"
                            value={billingZipCode}
                            onChange={(e) => setBillingZipCode(e.target.value)}
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="billingState" className="form-label">State</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingState"
                            value={billingState}
                            onChange={(e) => setBillingState(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <label htmlFor="billingCountry" className="form-label">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            id="billingCountry"
                            value={billingCountry}
                            onChange={(e) => setBillingCountry(e.target.value)}
                          />
                        </div>
                      </div>
                    </fieldset>
                    <div className="form-check mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="shippingAsBilling"
                        checked={shippingAsBilling}
                        onChange={() => setShippingAsBilling(!shippingAsBilling)}
                      />
                      <label className="form-check-label" htmlFor="shippingAsBilling">
                        Use billing details for shipping
                      </label>
                    </div>
                    {!shippingAsBilling && (
                      <fieldset className="mb-3">
                        <legend>Shipping Details</legend>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="shippingContactName" className="form-label">Contact Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingContactName"
                              value={shippingContactName}
                              onChange={(e) => setShippingContactName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="shippingPhone" className="form-label">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingPhone"
                              value={shippingPhone}
                              onChange={(e) => setShippingPhone(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="shippingAddress" className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingAddress"
                              value={shippingAddress}
                              onChange={(e) => setShippingAddress(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="shippingCity" className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingCity"
                              value={shippingCity}
                              onChange={(e) => setShippingCity(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="shippingZipCode" className="form-label">Zip Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingZipCode"
                              value={shippingZipCode}
                              onChange={(e) => setShippingZipCode(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="shippingState" className="form-label">State</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingState"
                              value={shippingState}
                              onChange={(e) => setShippingState(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <label htmlFor="shippingCountry" className="form-label">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippingCountry"
                              value={shippingCountry}
                              onChange={(e) => setShippingCountry(e.target.value)}
                            />
                          </div>
                        </div>
                      </fieldset>
                    )}
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddClientAndSupplier;
