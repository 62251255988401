import React, { useState, useEffect } from 'react';
import { Button, Table, Modal, Dropdown } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed
import BankTransferModal from './BankTransferModal'; // Import the modal component
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { CSVLink } from 'react-csv';

const ViewBankTransfer = () => {
  const [bankTransfers, setBankTransfers] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [currentTransfer, setCurrentTransfer] = useState(null); // To hold the transfer to be edited
  const [mode, setMode] = useState('create');
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch the list of bank transfers when the component mounts
    const fetchBankTransfers = async () => {
      try {
        const response = await axiosInstance.get('/api/bank-transfers/', {
            params: { user: storedUserId, company: storedCompanyId }
          });
        setBankTransfers(response.data);
      } catch (error) {
        console.error('Error fetching bank transfers:', error);
      }
    };

    fetchBankTransfers();
  }, [modalShow]); // Re-fetch data when the modal closes or opens

  const handleAddTransfer = () => {
    setMode('create');
    setCurrentTransfer(null);
    setModalShow(true);
  };

  const handleEditTransfer = (transfer) => {
    setMode('edit');
    setCurrentTransfer(transfer);
    setModalShow(true);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredTransfers = bankTransfers.filter(transfer => {
    const fromBank = typeof transfer.from_bank === 'string' ? transfer.from_bank : '';
    const toBank = typeof transfer.to_bank === 'string' ? transfer.to_bank : '';
    const description = typeof transfer.description === 'string' ? transfer.description : '';
  
    return (
      fromBank.toLowerCase().includes(search.toLowerCase()) ||
      toBank.toLowerCase().includes(search.toLowerCase()) ||
      description.toLowerCase().includes(search.toLowerCase())
    );
  });
  

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Bank Transfers</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={bankTransfers}
                        filename={"bank-transfers-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <Button variant="primary" onClick={handleAddTransfer} className="float-right">
                      Add Transfer
                    </Button>
                  </div>

                  <Table striped bordered hover className="table table-striped text-center">
                    <thead>
                      <tr>
                        <th>From Bank</th>
                        <th>To Bank</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTransfers.slice(0, rowsPerPage).map((transfer) => (
                        <tr key={transfer.id}>
                          <td>{transfer.from_bank}</td>
                          <td>{transfer.to_bank}</td>
                          <td>{transfer.amount}</td>
                          <td>{new Date(transfer.transfer_date).toLocaleDateString()}</td>
                          <td>
                            <Button variant="info" size="sm" className="me-2" onClick={() => handleEditTransfer(transfer)}>
                              Edit
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {filteredTransfers.length === 0 && (
                    <p className="text-center">No bank transfers available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* BankTransferModal for adding/editing transfers */}
      <BankTransferModal
        show={modalShow}
        handleClose={() => setModalShow(false)}
        bankTransfer={currentTransfer}
        mode={mode}
      />
    </div>
  );
};

export default ViewBankTransfer;
