import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";

const ViewPOSOrder = () => {
  const [orderNumber, setOrderNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [table, setTable] = useState("");
  const [customer, setCustomer] = useState("");
  const [waiter, setWaiter] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [orderType, setOrderType] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [modifiedDate, setModifiedDate] = useState("");
  const [totalTaxableAmount, setTotalTaxableAmount] = useState("");
  const [totalVAT, setTotalVAT] = useState("");
  const [discount, setDiscount] = useState("");
  const [subTotalAfterDiscount, setSubTotalAfterDiscount] = useState("");
  const [taxAfterDiscount, setTaxAfterDiscount] = useState("");
  const [totalAmountDue, setTotalAmountDue] = useState("");

  const [items, setItems] = useState([
    {
      id: "",
      name: "",
      price: "",
      quantity: "",
      taxableAmount: "",
      vatAmount: "",
      total: "",
    },
  ]);

  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);
  }, []);

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setItems(updatedItems);
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>View POS Order</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="orderNumber" className="form-label">
                          Order Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="orderNumber"
                          value={orderNumber}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="invoiceNumber" className="form-label">
                          Invoice Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="invoiceNumber"
                          value={invoiceNumber}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="table" className="form-label">
                          Table
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="table"
                          value={table}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="customer" className="form-label">
                          Customer
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="customer"
                          value={customer}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="waiter" className="form-label">
                          Waiter
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="waiter"
                          value={waiter}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="paymentMethod" className="form-label">
                          Payment Method
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="paymentMethod"
                          value={paymentMethod}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="orderType" className="form-label">
                          Order Type
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="orderType"
                          value={orderType}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="createdDate" className="form-label">
                          Created Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="createdDate"
                          value={createdDate}
                          readOnly
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="modifiedDate" className="form-label">
                          Modified Date
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="modifiedDate"
                          value={modifiedDate}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>Name</th>
                              <th>Price</th>
                              <th>Taxable Amount</th>
                              <th>VAT Amount</th>
                              <th>Total Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>{item.price}</td>
                                <td>{item.taxable_amount}</td>
                                <td>{item.vat_amount}</td>
                                <td>{item.total_amount}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="totalTaxableAmount"
                              className="form-label"
                            >
                              Total Taxable Amount
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="totalTaxableAmount"
                              value={totalTaxableAmount}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="totalVAT" className="form-label">
                              Total VAT
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="totalVAT"
                              value={totalVAT}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="discount" className="form-label">
                              Discount
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="discount"
                              value={discount}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="subTotalAfterDiscount"
                              className="form-label"
                            >
                              Sub Total after Discount
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="subTotalAfterDiscount"
                              value={subTotalAfterDiscount}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="taxAfterDiscount"
                              className="form-label"
                            >
                              Tax after Discount
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="taxAfterDiscount"
                              value={taxAfterDiscount}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 mb-3">
                        <div className="row">
                          <div className="col-md-6">
                            <label
                              htmlFor="totalAmountDue"
                              className="form-label"
                            >
                              Total Amount Due
                            </label>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              id="totalAmountDue"
                              value={totalAmountDue}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ViewPOSOrder;
