// ProductBills.js
import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance";
import { Button } from "react-bootstrap";

const mockProductData = {
  products: [
    {
      id: "1",
      name: "Product A",
      price: 100.0,
    },
    {
      id: "2",
      name: "Product B",
      price: 150.0,
    },
    {
      id: "3",
      name: "Product C",
      price: 200.0,
    },
  ],
};
const ProductBills = ({ selectedCategory, onProductClick }) => {
  const [products, setProducts] = useState([]);

  //   useEffect(() => {
  //     if (selectedCategory) {
  //       // Fetch products based on the selected category
  //       axiosInstance
  //         .get(`/api/products?category=${selectedCategory}`)
  //         .then((response) => {
  //           setProducts(response.data);
  //         })
  //         .catch((error) => {
  //           console.error("Error fetching products", error);
  //         });
  //     }
  //   }, [selectedCategory]);
  useEffect(() => {
    // Simulate an API call
    const fetchProducts = () => {
      setProducts(mockProductData.products);
    };

    fetchProducts();
  }, []);
  return (
    <div className="product-bills">
      {products.map((product) => (
        <Button
          key={product.id}
          variant="primary"
          onClick={() => onProductClick(product)}
          className="m-2"
        >
          {product.name}
        </Button>
      ))}
    </div>
  );
};

export default ProductBills;
