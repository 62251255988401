import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const PurchaseOrderModal = ({ show, handleClose, purchaseOrder, mode }) => {
  const [formData, setFormData] = useState({
    supplier: '',
    order_date: '',
    total_amount: '',
    status: 'draft',
    Company: localStorage.getItem('company_id'),
  });

  const [suppliers, setSuppliers] = useState([]);

  useEffect(() => {
    // Fetch suppliers when the component mounts
    const fetchSuppliers = async () => {
      try {
        const response = await axiosInstance.get('/api/supplier/', {
          params: { user: localStorage.getItem('user_id'), company: localStorage.getItem('company_id') }
        });
        setSuppliers(response.data);
        console.log('Suppliers fetched:', response.data);
      } catch (error) {
        console.error('Error fetching suppliers', error);
      }
    };

    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (mode === 'view' || mode === 'edit') {
      setFormData({
        ...purchaseOrder,
        company: localStorage.getItem('company_id'), // Ensure company is included
      });
    }
  }, [purchaseOrder, mode]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    console.log(formData);
    try {
      if (mode === 'create') {
        await axiosInstance.post('/api/purchase-orders/create/', formData, {
          params: { user: localStorage.getItem('user_id'), company: localStorage.getItem('company_id') }
        });
      } else {
        await axiosInstance.put(`/api/purchase-orders/update/${purchaseOrder.id}/`, formData, {
          params: { user: localStorage.getItem('user_id'), company: localStorage.getItem('company_id') }
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error saving purchase order', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Add Purchase Order' : 'Purchase Order Details'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formSupplier">
            <Form.Label>Supplier</Form.Label>
            <Form.Control
              as="select"
              name="supplier"
              value={formData.supplier}
              onChange={handleChange}
              disabled={mode === 'view'}
            >
              <option value="">Select a Supplier</option>
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.english_name} {/* Adjust to the correct supplier property */}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formOrderDate">
            <Form.Label>Order Date</Form.Label>
            <Form.Control
              type="date"
              name="order_date"
              value={formData.order_date}
              onChange={handleChange}
              disabled={mode === 'view'}
            />
          </Form.Group>
          <Form.Group controlId="formTotalAmount">
            <Form.Label>Total Amount</Form.Label>
            <Form.Control
              type="number"
              name="total_amount"
              value={formData.total_amount}
              onChange={handleChange}
              disabled={mode === 'view'}
            />
          </Form.Group>
          <Form.Group controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              name="status"
              value={formData.status}
              onChange={handleChange}
              disabled={mode === 'view'}
            >
              <option value="draft">Draft</option>
              <option value="sent">Sent</option>
              <option value="received">Received</option>
              <option value="cancelled">Cancelled</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      {mode !== 'view' && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>{mode === 'create' ? 'Create' : 'Update'}</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default PurchaseOrderModal;
