import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';

const AddRawMaterial = () => {
  const [englishName, setEnglishName] = useState('');
  const [arabicName, setArabicName] = useState('');
  const [code, setCode] = useState('');
  const [unit, setUnit] = useState('');
  const [openingBalance, setOpeningBalance] = useState('');
  const [openingBalanceTotalPrice, setOpeningBalanceTotalPrice] = useState('');
  const [minimumQuantity, setMinimumQuantity] = useState('');
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('english_name', englishName);
    formData.append('arabic_name', arabicName);
    formData.append('code', code);
    formData.append('unit', unit);
    formData.append('opening_balance', openingBalance);
    formData.append('opening_balance_total_price', openingBalanceTotalPrice);
    formData.append('minimum_quantity', minimumQuantity);
    if (image) {
      formData.append('image', image);
    }

    axiosInstance.post('/api/raw-materials/', formData)
      .then((response) => {
        console.log('Raw material added successfully', response.data);
        navigate('/raw-materials');
      })
      .catch((error) => {
        console.error('Error adding raw material', error);
      });
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Raw Material</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="englishName" className="form-label">English Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="englishName"
                          value={englishName}
                          onChange={(e) => setEnglishName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="arabicName" className="form-label">Arabic Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="arabicName"
                          value={arabicName}
                          onChange={(e) => setArabicName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="code" className="form-label">Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="unit" className="form-label">Unit</label>
                        <input
                          type="text"
                          className="form-control"
                          id="unit"
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="openingBalance" className="form-label">Opening Balance</label>
                        <input
                          type="number"
                          className="form-control"
                          id="openingBalance"
                          value={openingBalance}
                          onChange={(e) => setOpeningBalance(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="openingBalanceTotalPrice" className="form-label">Opening Balance Total Price</label>
                        <input
                          type="number"
                          className="form-control"
                          id="openingBalanceTotalPrice"
                          value={openingBalanceTotalPrice}
                          onChange={(e) => setOpeningBalanceTotalPrice(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="minimumQuantity" className="form-label">Minimum Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          id="minimumQuantity"
                          value={minimumQuantity}
                          onChange={(e) => setMinimumQuantity(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="image" className="form-label">Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddRawMaterial;
