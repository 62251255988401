// src/components/pages/CompanyRegistration.js

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../assets/css/style.css'; // Import your custom CSS
import axiosInstance from '../utils/axiosInstance';

const CompanyRegistration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [plan, setPlan] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    adminName: '',
    password: '',
    terms: false
  });

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const planFromUrl = query.get('plan');
    setPlan(planFromUrl);
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
        const response = await axiosInstance.get('/api/register/', {
        ...formData,
        planType: plan
      });
      const companyId = response.data.id;
      navigate(`/profile/${companyId}`);// Redirect to profile page on success
    } catch (error) {
      alert('Error: ' + error.response.data.detail || 'Registration failed');
    }
  };

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a href="/" className="logo d-flex align-items-center w-auto">
                    <img src="../assets/img/logo.png" alt="Logo" />
                    <span className="d-none d-lg-block">NiceAdmin</span>
                  </a>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Create an Account</h5>
                      <p className="text-center small">Enter your Company details to create account</p>
                    </div>

                    <form id="registerForm" className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>
                      <div className="col-12">
                        <label htmlFor="name" className="form-label">Company Name</label>
                        <input type="text" name="name" className="form-control" id="name" value={formData.name} onChange={handleChange} required />
                        <div className="invalid-feedback">Please, enter your company name!</div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="email" className="form-label">Company Admin Email</label>
                        <input type="email" name="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                        <div className="invalid-feedback">Please enter a valid Email address!</div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="adminName" className="form-label">Company Admin Name</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text" id="inputGroupPrepend">@</span>
                          <input type="text" name="adminName" className="form-control" id="adminName" value={formData.adminName} onChange={handleChange} required />
                          <div className="invalid-feedback">Please choose a Admin Name.</div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" name="password" className="form-control" id="password" value={formData.password} onChange={handleChange} required />
                        <div className="invalid-feedback">Please enter your password!</div>
                      </div>

                      <div className="col-12">
                        <div className="form-check">
                          <input className="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" checked={formData.terms} onChange={handleChange} required />
                          <label className="form-check-label" htmlFor="acceptTerms">I agree and accept the <a href="#">terms and conditions</a></label>
                          <div className="invalid-feedback">You must agree before submitting.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">Create Account</button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">Already have an account? <a href="pages-login.html">Log in</a></p>
                      </div>
                    </form>

                  </div>
                </div>

                <div className="credits">
                  Designed by <a href="#">Toimitek</a>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default CompanyRegistration;
