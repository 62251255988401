import React from 'react';
import addIcon from '../../assets/img/icons8-plus-24.png'
const AddBtn = ({ onClick }) => {
  return (
    <div
      role="button"
      tabIndex="0" // Makes the div focusable
      style={{
        backgroundColor: "#fff",
        //color: "#fff",
        // padding: "0rem 0.5rem",
        borderRadius: "0.25rem",
        cursor: "pointer",
        fontSize: "0.65rem", // equivalent to size="sm"
        display: "inline-block",
        border:"1px solid #000"
      }}
      className="mx-2"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      <img src={addIcon} alt="Add Icon" style={{ width: '20px', height: '20px' }} />
    </div>
  );
};

export default AddBtn;
