import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance';

const ClientAndSupplierModal = ({ show, handleClose, clientAndSupplierData, isEditMode, onSave }) => {
  const [clientAndSupplier, setClientAndSupplier] = useState({
    id: '',
    status: '',
    type: '',
    english_name: '',
    arabic_name: '',
    email: '',
    phone: '',
    tax_number: '',
    tax_percent: '',
    contact_name: '',
    billing_phone: '',
    billing_address: '',
    billing_city: '',
    billing_zip_code: '',
    billing_state: '',
    billing_country: '',
    shipping_contact_name: '',
    shipping_phone: '',
    shipping_address: '',
    shipping_city: '',
    shipping_zip_code: '',
    shipping_state: '',
    shipping_country: ''
  });

  useEffect(() => {
    console.log()
    if (clientAndSupplierData) {
      // Fetch details if in edit mode or view mode
      axiosInstance.get(`/api/client-suppliers/${clientAndSupplierData}/`)
        .then(response => {
          setClientAndSupplier(response.data);
        })
        .catch(error => {
          console.error('Error fetching client and supplier details', error);
        });
    }
  }, [clientAndSupplierData, show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientAndSupplier({ ...clientAndSupplier, [name]: value });
  };

  const handleSave = () => {
    if (isEditMode) {
      // Update the existing client or supplier
      axiosInstance.put(`/api/client-suppliers/${clientAndSupplier.id}/`, clientAndSupplier)
        .then(response => {
          onSave(response.data); // Call the onSave callback with the updated data
          handleClose(); // Close the modal
        })
        .catch(error => {
          console.error('Error updating client or supplier', error);
          // Handle error, maybe show an alert or notification
        });
    } else {
      // Create a new client or supplier
      axiosInstance.post('/api/client-suppliers/', clientAndSupplier)
        .then(response => {
          onSave(response.data); // Call the onSave callback with the new data
          handleClose(); // Close the modal
        })
        .catch(error => {
          console.error('Error creating new client or supplier', error);
          // Handle error, maybe show an alert or notification
        });
    }
  };
  

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Update Client/Supplier' : 'View Client/Supplier'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierStatus">
                <Form.Label>Status</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="select"
                    name="status"
                    value={clientAndSupplier.status}
                    onChange={handleChange}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Form.Control>
                ) : (
                  <p>{clientAndSupplier.status === 'active' ? 'Active' : 'Inactive'}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierType">
                <Form.Label>Type</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="select"
                    name="type"
                    value={clientAndSupplier.type}
                    onChange={handleChange}
                  >
                    <option value="client">Client</option>
                    <option value="supplier">Supplier</option>
                  </Form.Control>
                ) : (
                  <p>{clientAndSupplier.type === 'client' ? 'Client' : 'Supplier'}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierEnglishName">
                <Form.Label>English Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="english_name"
                    value={clientAndSupplier.english_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.english_name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierArabicName">
                <Form.Label>Arabic Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="arabic_name"
                    value={clientAndSupplier.arabic_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.arabic_name}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierEmail">
                <Form.Label>Email</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="email"
                    name="email"
                    value={clientAndSupplier.email}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.email}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierPhone">
                <Form.Label>Phone</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="phone"
                    value={clientAndSupplier.phone}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.phone}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierTaxNumber">
                <Form.Label>Tax Number</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="tax_number"
                    value={clientAndSupplier.tax_number}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.tax_number}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierTaxPercent">
                <Form.Label>Tax Percent</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="number"
                    name="tax_percent"
                    value={clientAndSupplier.tax_percent}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.tax_percent}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          clientAndSupplierId
          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierContactName">
                <Form.Label>Contact Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="contact_name"
                    value={clientAndSupplier.contact_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.contact_name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierBillingPhone">
                <Form.Label>Billing Phone</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="billing_phone"
                    value={clientAndSupplier.billing_phone}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_phone}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group controlId="clientAndSupplierBillingAddress">
                <Form.Label>Billing Address</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="textarea"
                    name="billing_address"
                    value={clientAndSupplier.billing_address}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_address}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierBillingCity">
                <Form.Label>Billing City</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="billing_city"
                    value={clientAndSupplier.billing_city}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_city}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierBillingZipCode">
                <Form.Label>Billing Zip Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="billing_zip_code"
                    value={clientAndSupplier.billing_zip_code}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_zip_code}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierBillingState">
                <Form.Label>Billing State</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="billing_state"
                    value={clientAndSupplier.billing_state}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_state}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierBillingCountry">
                <Form.Label>Billing Country</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="billing_country"
                    value={clientAndSupplier.billing_country}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.billing_country}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingContactName">
                <Form.Label>Shipping Contact Name</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_contact_name"
                    value={clientAndSupplier.shipping_contact_name}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_contact_name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingPhone">
                <Form.Label>Shipping Phone</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_phone"
                    value={clientAndSupplier.shipping_phone}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_phone}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group controlId="clientAndSupplierShippingAddress">
                <Form.Label>Shipping Address</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    as="textarea"
                    name="shipping_address"
                    value={clientAndSupplier.shipping_address}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_address}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingCity">
                <Form.Label>Shipping City</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_city"
                    value={clientAndSupplier.shipping_city}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_city}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingZipCode">
                <Form.Label>Shipping Zip Code</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_zip_code"
                    value={clientAndSupplier.shipping_zip_code}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_zip_code}</p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingState">
                <Form.Label>Shipping State</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_state"
                    value={clientAndSupplier.shipping_state}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_state}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="clientAndSupplierShippingCountry">
                <Form.Label>Shipping Country</Form.Label>
                {isEditMode ? (
                  <Form.Control
                    type="text"
                    name="shipping_country"
                    value={clientAndSupplier.shipping_country}
                    onChange={handleChange}
                  />
                ) : (
                  <p>{clientAndSupplier.shipping_country}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      {isEditMode && (
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ClientAndSupplierModal;
