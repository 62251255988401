import React from 'react';

const EditBtn = ({ onClick }) => {
  return (
    <div
      role="button"
      tabIndex="0" // Makes the div focusable
      style={{
        backgroundColor: "#fd7e14",
        color: "#fff",
        padding: "0rem 0.5rem",
        borderRadius: "0.25rem",
        cursor: "pointer",
        fontSize: "0.65rem", // equivalent to size="sm"
        display: "inline-block",
      }}
      className="mx-2"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
    >
      Update
    </div>
  );
};

export default EditBtn;
