import React, { useState, useEffect } from "react";
import CompanyModal from "./CompanyModal";
import logo from "../assets/img/logo1.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/PlanModal.css';  // Import the custom styles
import 'bootstrap-icons/font/bootstrap-icons.css';
import Header from "../master-layout/Header";
import AdminSidebar from "../master-layout/AdminSidebar";
import axiosInstance from '../utils/axiosInstance';

const Company = () => {
  const [companies, setCompanies] = useState([]);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [plans, setPlans] = useState([]);

  // Function to fetch companies from backend
  const fetchCompanies = () => {
    axiosInstance.get('/api/companies')
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the companies!", error);
      });
  };

  // Function to fetch plans from backend
  const fetchPlans = () => {
    axiosInstance.get("/api/plans/")
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the plans!", error);
      });
  };

  useEffect(() => {
    fetchCompanies();
    fetchPlans();
  }, []);

  // Company Modal handlers
  const handleOpenCompanyModal = () => setShowCompanyModal(true);
  const handleCloseCompanyModal = () => setShowCompanyModal(false);

  // Callback function to refresh company list after adding a new company
  const handleCompanyAdded = () => {
    fetchCompanies();  // Refresh the list of companies
  };

  const handleRenewPlan = (companyId) => {
    console.log(`Renew plan for company with ID: ${companyId}`);
    axiosInstance.post('/api/companies/${companyId}/renew/')
      .then((response) => {
        console.log(response.data.message);
        fetchCompanies();  // Optionally, refetch the company data to update the UI
      })
      .catch((error) => {
        console.error("There was an error renewing the plan!", error);
      });
  };

  return (
    <div>
      {/* Header */}
      <Header />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Companies</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active">Companies</li>
            </ol>
          </nav>
        </div>

        {/* Sidebar */}
        <AdminSidebar />

        <section className="section dashboard">
          <div className="row">
            {/* Companies Cards */}
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end mb-3">
                    <button
                      className="btn btn-primary d-flex align-items-center justify-content-center"
                      onClick={handleOpenCompanyModal}
                      style={{ fontSize: "24px", marginTop: "10px", width: "50px", height: "50px", padding: 0 }}
                    >
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>
                  <div className="row">
                    {companies.map((company) => (
                      <div className="col-md-4 mb-4" key={company.id}>
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">{company.name}</h5>
                            <p className="card-text">Email: {company.email}</p>
                            <p className="card-text">Plan: {company.plan_name || "N/A"}</p>
                            <button
                              className="btn btn-warning"
                              onClick={() => handleRenewPlan(company.id)}
                            >
                              Renew Plan
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Company Modal */}
      <CompanyModal 
        show={showCompanyModal} 
        handleClose={handleCloseCompanyModal} 
        plans={plans} 
        onCompanyAdded={handleCompanyAdded}  // Pass the callback function
      />
    </div>
  );
};

export default Company;
