import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import PurchaseOrderModal from './PurchaseOrderModal'; // Create this modal component

const ViewPurchaseOrder = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [isEditMode, setIsEditMode] = useState(''); // Modal mode

  const navigate = useNavigate();
  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  // Fetch purchase orders on component mount
  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  const fetchPurchaseOrders = async () => {
    try {
      const response = await axiosInstance.get('/api/purchase-orders/', {
        params: { user: storedUserId, company: storedCompanyId }
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching purchase orders', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter(item => {
    const supplierName = item.supplier && typeof item.supplier.name === 'string' ? item.supplier.name : '';
    return (
      supplierName.toLowerCase().includes(search.toLowerCase()) ||
      item.status.toLowerCase().includes(search.toLowerCase())
    );
  });

  const handleShowModal = async (id, editMode) => {
    setIsEditMode(editMode);
    
    try {
      if (editMode === 'view') {
        const response = await axiosInstance.get(`/api/purchase-orders/${id}/`, {
            params: { user: storedUserId, company: storedCompanyId }
        });
        console.log("SsssssssssssssssssssssssssssssSSSSSSSSSSSSSS",response.data);
        setSelectedPurchaseOrder(response.data);
      } else {
        const purchaseOrder = data.find(item => item.id === id);
        setSelectedPurchaseOrder(purchaseOrder);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching purchase order details', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPurchaseOrder(null);
    fetchPurchaseOrders(); // Refresh data after close
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Purchase Order List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={data}
                        filename={"purchase-order-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <Button variant="primary" onClick={() => handleShowModal(null, 'create')} className="float-right">
                      Add Purchase Order
                    </Button>
                  </div>

                  <table className="table table-striped text-center">
                    <thead>
                      <tr>
                        <th>Supplier</th>
                        <th>Order Date</th>
                        <th>Total Amount</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((item) => (
                        <tr key={item.id}>
                          <td>{item.supplier}</td>
                          <td>{item.order_date}</td>
                          <td>{item.total_amount}</td>
                          <td>{item.status}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, 'view')}
                            >
                              View Details
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, 'edit')}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredData.length === 0 && (
                    <p className="text-center">No purchase orders available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <PurchaseOrderModal
        show={showModal}
        handleClose={handleCloseModal}
        purchaseOrder={selectedPurchaseOrder}
        mode={isEditMode}
      />
    </div>
  );
};

export default ViewPurchaseOrder;
