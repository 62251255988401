import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const AccountsPayableModal = ({ show, handleClose, accountsPayable, mode }) => {
  const [formData, setFormData] = useState({
    supplier: '',
    amount: '',
    due_date: '',
    description: '',
    paid: false,
  });
  const [suppliers, setSuppliers] = useState([]);
  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch supplier data
    const fetchSuppliers = async () => {
      try {
        const response = await axiosInstance.get('/api/suppliers/', {
          params: { user: storedUserId, company: storedCompanyId }
        });
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching suppliers', error);
      }
    };

    fetchSuppliers();

    if (mode === 'edit' && accountsPayable) {
      setFormData({
        supplier: accountsPayable.supplier,
        amount: accountsPayable.amount,
        due_date: accountsPayable.due_date,
        description: accountsPayable.description,
        paid: accountsPayable.paid,
      });
    }
  }, [accountsPayable, mode, storedUserId, storedCompanyId]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (mode === 'create') {
        await axiosInstance.post('/api/accounts-payable/', formData, {
          params: { user: storedUserId, company: storedCompanyId }
        });
      } else if (mode === 'edit' && accountsPayable) {
        await axiosInstance.put(`/api/accounts-payable/${accountsPayable.id}/`, formData, {
          params: { user: storedUserId, company: storedCompanyId }
        });
      }
      handleClose();
    } catch (error) {
      console.error('Error saving accounts payable', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ top: '100px' }}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Add Accounts Payable' : 'Edit Accounts Payable'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formSupplier">
            <Form.Label>Supplier</Form.Label>
            <Form.Control
              as="select"
              name="supplier"
              value={formData.supplier}
              onChange={handleInputChange}
              required
            >
              <option value="">Select Supplier</option>
              {suppliers.map((supplier) => (
                <option key={supplier.id} value={supplier.id}>
                  {supplier.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formAmount">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDueDate">
            <Form.Label>Due Date</Form.Label>
            <Form.Control
              type="date"
              name="due_date"
              value={formData.due_date}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </Form.Group>
          <Form.Group controlId="formPaid">
            <Form.Label>Paid</Form.Label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="paid"
                checked={formData.paid}
                onChange={handleInputChange}
              />
              <label className="form-check-label">
                {formData.paid ? 'Yes' : 'No'}
              </label>
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            {mode === 'create' ? 'Add' : 'Update'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AccountsPayableModal;
