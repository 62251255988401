import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/img/logo1.png";
import profile from "../assets/img/profile-img.jpg";
import LanguageSwitcher from "./LanguageSwitcher"; // Import LanguageSwitcher
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { i18n } = useTranslation();
  const [displayName, setDisplayName] = useState("");
  const currentLanguage = i18n.language; // Get current language
  const isRTL = currentLanguage === "ar"; // Check if RTL
  const dashboard_type =
    localStorage.getItem("user_type") === "toimitekAdmin"
      ? "/admin-dashboard"
      : "dashboard";
  const navigate = useNavigate();

  // Log current language whenever it changes
  useEffect(() => {
    console.log("Current Language: ", currentLanguage);
    document.body.dir = isRTL ? "rtl" : "ltr"; // Set the document's direction
  }, [currentLanguage, isRTL]);

  const handleLogout = () => {
    // Clear localStorage and redirect to login page
    localStorage.clear();
    navigate("/");
  };

  return (
    <header
      id="header"
      className={`header fixed-top d-flex align-items-center ${
        isRTL ? "rtl-header" : "ltr-header"
      }`} // Add dynamic class based on language direction
    >
      <div className="d-flex align-items-center justify-content-between">
        <a href={dashboard_type} className="logo d-flex align-items-center">
          <img src={logo} alt="Logo" />
        </a>
        <i className="bi bi-list toggle-sidebar-btn"></i>
      </div>

      <div className={`search-bar ${isRTL ? "me-auto" : "ms-auto"}`}>
        <form
          className="search-form d-flex align-items-center"
          method="POST"
          action="#"
        >
          <input
            type="text"
            name="query"
            placeholder={isRTL ? "بحث" : "Search"}
            title={isRTL ? "أدخل كلمة البحث" : "Enter search keyword"}
          />
          <button type="submit" title={isRTL ? "بحث" : "Search"}>
            <i className="bi bi-search"></i>
          </button>
        </form>
      </div>

      <nav className={`header-nav ${isRTL ? "me-auto" : "ms-auto"}`}>
        <ul className="d-flex align-items-center">
          {/* Language Switcher */}
          <li className="nav-item">
            <LanguageSwitcher />
          </li>

          {/* Profile Dropdown */}
          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img src={profile} alt="Profile" className="rounded-circle" />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {displayName}
              </span>
            </a>
            <ul
              className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${
                isRTL ? "dropdown-menu-start" : "dropdown-menu-end"
              }`}
            >
              <li className="dropdown-header">
                <h6>{displayName}</h6>
                <span>
                  {localStorage.getItem("user_type") === "toimitekAdmin"
                    ? "Administrator"
                    : "Company User"}
                </span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button
                  className="dropdown-item d-flex align-items-center"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>{isRTL ? "تسجيل خروج" : "Logout"}</span>
                </button>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
