import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import axiosInstance from "../../utils/axiosInstance";

const AddProductService = () => {
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [type, setType] = useState("Product");
  const [hasRawMaterials, setHasRawMaterials] = useState(false);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [code, setCode] = useState("");
  const [status, setStatus] = useState("Active");
  const [category, setCategory] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseAccount, setPurchaseAccount] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [saleAccount, setSaleAccount] = useState("");
  const [image, setImage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRawMaterials, setSelectedRawMaterials] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");
  const companyId = localStorage.getItem("company_id");
  useEffect(() => {
    if (userId && companyId) {
      axiosInstance
        .get("/api/companies/", {
          params: { user: userId, company: companyId },
        })
        .then((response) => setCompanies(response.data))
        .catch((error) => console.error("Error fetching companies", error));

      if (type === "product" && hasRawMaterials) {
        axiosInstance
          .get("/api/raw-materials/", {
            params: { user: userId, company: companyId },
          })
          .then((response) => setRawMaterials(response.data))
          .catch((error) =>
            console.error("Error fetching raw materials", error)
          );
      }
    }
  }, [type, hasRawMaterials]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("english_name", englishName);
    formData.append("arabic_name", arabicName);
    formData.append("code", code);
    formData.append("image", image);
    if (image) {
      formData.append("image", image);
    }
    formData.append("purchase_price", purchasePrice);
    formData.append("purchase_account", purchaseAccount);
    formData.append("sale_price", salePrice);
    formData.append("sale_account", saleAccount);
    formData.append("category", category);
    formData.append("status", status);
    formData.append("type", type);
    formData.append("has_raw_materials", hasRawMaterials);
    formData.append("raw_materials", JSON.stringify(selectedRawMaterials));
    formData.append("company", selectedCompany);
    formData.append("user", userId);

    axiosInstance
      .post("/api/products-services/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Product/Service added successfully", response.data);
        navigate("/viewps");
      })
      .catch((error) => {
        console.error("Error adding product/service", error);
      });
  };

  const handleRawMaterialSelect = (id, index) => {
    const selectedMaterial = rawMaterials.find(
      (material) => material.id === id
    );
    const updatedRawMaterials = [...selectedRawMaterials];
    updatedRawMaterials[index] = {
      id,
      english_name: selectedMaterial.english_name,
      arabic_name: selectedMaterial.arabic_name,
      unit: selectedMaterial.unit,
      price: selectedMaterial.opening_balance_total_price,
      measurement: "",
    };
    setSelectedRawMaterials(updatedRawMaterials);
  };

  const handleMeasurementChange = (index, value) => {
    const updatedRawMaterials = [...selectedRawMaterials];
    updatedRawMaterials[index].measurement = value;
    setSelectedRawMaterials(updatedRawMaterials);
  };

  const handleSaveRawMaterial = (index) => {
    console.log("Raw Material Saved:", selectedRawMaterials[index]);
    // Handle saving the raw material information if necessary
  };

  const handleAddRawMaterialRow = () => {
    setSelectedRawMaterials([...selectedRawMaterials, {}]);
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Product/Service</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label htmlFor="englishName" className="form-label">
                          English Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="englishName"
                          value={englishName}
                          onChange={(e) => setEnglishName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="arabicName" className="form-label">
                          Arabic Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="arabicName"
                          value={arabicName}
                          onChange={(e) => setArabicName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label htmlFor="code" className="form-label">
                          Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="category" className="form-label">
                          Category
                        </label>
                        <select
                          className="form-select"
                          id="category"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          <option value="category1">Category 1</option>
                          <option value="category2">Category 2</option>
                        </select>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label htmlFor="purchasePrice" className="form-label">
                          Purchase Price
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="purchasePrice"
                          value={purchasePrice}
                          onChange={(e) => setPurchasePrice(e.target.value)}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="purchaseAccount" className="form-label">
                          Purchase Account
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="purchaseAccount"
                          value={purchaseAccount}
                          onChange={(e) => setPurchaseAccount(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label htmlFor="salePrice" className="form-label">
                          Sale Price
                        </label>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          id="salePrice"
                          value={salePrice}
                          onChange={(e) => setSalePrice(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="saleAccount" className="form-label">
                          Sale Account
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="saleAccount"
                          value={saleAccount}
                          onChange={(e) => setSaleAccount(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <div className="col-md-6">
                        <label htmlFor="company" className="form-label">
                          Company
                        </label>
                        <select
                          className="form-select"
                          id="company"
                          value={selectedCompany}
                          onChange={(e) => setSelectedCompany(e.target.value)}
                          required
                        >
                          <option value="">Select Company</option>
                          {companies.map((company) => (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="image" className="form-label">
                          Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Status</label>
                      <select
                        className="form-select"
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Type</label>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="productType"
                          value="product"
                          checked={type === "product"}
                          onChange={(e) => setType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="productType"
                        >
                          Product
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          id="serviceType"
                          value="service"
                          checked={type === "service"}
                          onChange={(e) => setType(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="serviceType"
                        >
                          Service
                        </label>
                      </div>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Has Raw Materials</label>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="hasRawMaterials"
                          checked={hasRawMaterials}
                          onChange={(e) => setHasRawMaterials(e.target.checked)}
                          disabled={type !== "product"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="hasRawMaterials"
                        >
                          Yes
                        </label>
                      </div>
                    </div>

                    {type === "product" && hasRawMaterials && (
                      <div className="mb-3">
                        <h5>Raw Materials</h5>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>English Name</th>
                              <th>Arabic Name</th>
                              <th>Unit</th>
                              <th>Price</th>
                              <th>Measurement</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedRawMaterials.map((material, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    className="form-select"
                                    value={material.id || ""}
                                    onChange={(e) =>
                                      handleRawMaterialSelect(
                                        parseInt(e.target.value),
                                        index
                                      )
                                    }
                                  >
                                    <option value="">
                                      Select Raw Material
                                    </option>
                                    {rawMaterials.map((rm) => (
                                      <option key={rm.id} value={rm.id}>
                                        {rm.english_name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>{material.arabic_name || ""}</td>
                                <td>{material.unit || ""}</td>
                                <td>{material.price || ""}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={material.measurement || ""}
                                    onChange={(e) =>
                                      handleMeasurementChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => handleSaveRawMaterial(index)}
                                  >
                                    Save
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleAddRawMaterialRow}
                        >
                          Add Raw Material
                        </button>
                      </div>
                    )}

                    <button type="submit" className="btn btn-success">
                      Add Product/Service
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddProductService;
