import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";

const AddPOSOrder = () => {
  const [supplier, setSupplier] = useState("");
  const [supplierBillReference, setSupplierBillReference] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState("");
  const [accountFrom, setAccountFrom] = useState(""); // New state for account from
  const [accountTo, setAccountTo] = useState(""); // New state for account to
  const [items, setItems] = useState([
    {
      rawMaterial: "",
      item_price: "",
      quantity: "",
      discount: "",
      tax: "",
      subtotal: "",
      total: "",
    },
  ]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [accounts, setAccounts] = useState([]); // New state for accounts
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);

    // Fetch raw materials
    axiosInstance
      .get("/api/raw-materials/", {
        params: { user: storedUserId, company: storedCompanyId },
      })
      .then((response) => {
        setRawMaterials(response.data);
      })
      .catch((error) => {
        console.error("Error fetching raw materials", error);
      });

    // Fetch suppliers
    axiosInstance
      .get("/api/supplier/")
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
      });

    // Fetch accounts
    axiosInstance
      .get("/api/accounts/", {
        params: { user: storedUserId, company: storedCompanyId },
      })
      .then((response) => {
        setAccounts(response.data);
        setAccountFrom(response.data);
        setAccountTo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching accounts", error);
      });
  }, []);

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        rawMaterial: "",
        item_price: "",
        quantity: "",
        discount: "",
        tax: "",
        subtotal: "",
        total: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!userId || !companyId) {
      console.error("User ID or Company ID is missing");
      return;
    }
  
    try {
      // Post Bill
      const response = await axiosInstance.post(
        "/api/bills/create/",
        {
          supplier,
          supplier_bill_reference: supplierBillReference,
          issue_date: issueDate,
          due_date: dueDate,
          delivery_date: deliveryDate,
          purchase_order: purchaseOrder,
          account_from: accountFrom,
          account_to: accountTo,
          company: companyId, // Include company in the request body if needed
        },
        {
          params: {
            company: companyId,
            user: userId,
          },
        }
      );
  
      // Ensure `id` is the correct field in your response
      const billId = response.data.id;
      console.log(billId);
      if (!billId) {
        console.error("Bill ID is not returned from API");
        return;
      }
      
      // Post Bill Items
      await Promise.all(
        items.map((item) =>
          axiosInstance.post(
            "/api/bill-items/create/",
            {
              bill: billId,
              raw_material: item.rawMaterial,
              item_price: item.item_price,
              quantity: item.quantity,
              discount: item.discount,
              tax: item.tax,
              company: companyId, // Include company in the request body if needed
              sub_total: parseFloat(item.subtotal),
              total: parseFloat(item.total),
            },
            {
              params: {
                company: companyId,
                user: userId,
              },
            }
          )
        )
      );
  
      // Handle success
      setSupplier("");
      setSupplierBillReference("");
      setIssueDate("");
      setDueDate("");
      setDeliveryDate("");
      setPurchaseOrder("");
      setAccountFrom("");
      setAccountTo("");
      setItems([
        {
          rawMaterial: "",
          item_price: "",
          quantity: "",
          discount: "",
          tax: "",
          subtotal: "",
          total: "",
        },
      ]);
      navigate("/bills");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting bill data", error);
    }
  };
  

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Bill</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="supplier" className="form-label">
                          Supplier
                        </label>
                        <select
                          id="supplier"
                          className="form-select"
                          value={supplier}
                          onChange={(e) => setSupplier(e.target.value)}
                          required
                        >
                          <option value="">Select Supplier</option>
                          {suppliers.map((sup) => (
                            <option key={sup.id} value={sup.id}>
                              {sup.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="supplierBillReference"
                          className="form-label"
                        >
                          Supplier Bill Reference
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="supplierBillReference"
                          value={supplierBillReference}
                          onChange={(e) =>
                            setSupplierBillReference(e.target.value)
                          }
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="issueDate" className="form-label">
                          Issue Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="issueDate"
                          value={issueDate}
                          onChange={(e) => setIssueDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="dueDate" className="form-label">
                          Due Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dueDate"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="deliveryDate" className="form-label">
                          Delivery Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="deliveryDate"
                          value={deliveryDate}
                          onChange={(e) => setDeliveryDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="purchaseOrder" className="form-label">
                          Purchase Order
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="purchaseOrder"
                          value={purchaseOrder}
                          onChange={(e) => setPurchaseOrder(e.target.value)}
                        />
                      </div>
                      <div
                        className="col-md-12 mb-3"
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        Accounts
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="accountFrom" className="form-label">
                          Account From
                        </label>
                        <select
                          id="accountFrom"
                          className="form-select"
                          value={accountFrom}
                          onChange={(e) => setAccountFrom(e.target.value)}
                          required
                        >
                          <option value="">Select Account</option>
                          {accounts.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.account_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="accountTo" className="form-label">
                          Account To
                        </label>
                        <select
                          id="accountTo"
                          className="form-select"
                          value={accountTo}
                          onChange={(e) => setAccountTo(e.target.value)}
                          required
                        >
                          <option value="">Select Account</option>
                          {accounts.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.account_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Raw Material</th>
                              <th>Item Price</th>
                              <th>Quantity</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Subtotal</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    name="rawMaterial"
                                    className="form-select"
                                    value={item.rawMaterial}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  >
                                    <option value="">
                                      Select Raw Material
                                    </option>
                                    {rawMaterials.map((raw) => (
                                      <option key={raw.id} value={raw.id}>
                                        {raw.english_name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="item_price"
                                    className="form-control"
                                    value={item.item_price}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    value={item.quantity}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="discount"
                                    className="form-control"
                                    value={item.discount}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="tax"
                                    className="form-control"
                                    value={item.tax}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="subtotal"
                                    className="form-control"
                                    value={item.subtotal}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="total"
                                    className="form-control"
                                    value={item.total}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAddItem}
                      >
                        Add Item
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button type="submit" className="btn btn-success">
                          Save Bill
                        </button>
                      </div>
                    </div>
                    {error && (
                      <div className="alert alert-danger mt-3">{error}</div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddPOSOrder;
