import React from 'react';
import { CSVLink } from 'react-csv';
import csvExportIcon from '../../assets/img/icons8-excel-48.png';

const CSVExportBtn = ({ data, filename }) => {
  return (
    <CSVLink
      data={data}
      filename={filename}
      className="mx-2"
      style={{
        textDecoration: 'none', // Remove default link styling
      }}
    >
      <div
        role="button"
        tabIndex="0" // Makes the div focusable
        style={{
          backgroundColor: "#fff",
          borderRadius: "0.25rem",
          cursor: "pointer",
          fontSize: "0.65rem", // equivalent to size="sm"
          display: "inline-block",
          border: "1px solid #000",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            e.currentTarget.click(); // Simulate click on CSVLink
          }
        }}
      >
        <img src={csvExportIcon} alt="CSV Export Icon" style={{ width: '20px', height: '20px' }} />
      </div>
    </CSVLink>
  );
};

export default CSVExportBtn;
