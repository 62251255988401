import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { Dropdown, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CSVLink } from "react-csv";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import CategoryModal from "./CategoryModal";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import EditBtn from "../../ui-kit/TableActionBtns/EditBtn.jsx";
import ViewBtn from "../../ui-kit/TableActionBtns/ViewBtn.jsx";
import { useTranslation } from "react-i18next";

const ViewCategories = () => {
  const { t, i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(10); // Pagination size
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Modal mode

  const storedUserId = localStorage.getItem("user_id");
  const storedCompanyId = localStorage.getItem("company_id");

  useEffect(() => {
    if (storedUserId && storedCompanyId) {
      axiosInstance
        .get("/api/categories/", {
          params: { user: storedUserId, company: storedCompanyId },
        })
        .then((response) => setCategories(response.data))
        .catch((error) => console.error("Error fetching categories", error));
    } else {
      console.error("User or company ID not found in localStorage");
    }
  }, [storedUserId, storedCompanyId]);

  const handleOpenModal = (category, editMode) => {
    setSelectedCategory(category);
    setIsEditMode(editMode);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSaveCategory = (updatedCategory) => {
    if (isEditMode) {
      axiosInstance
        .put(`/api/categories/${updatedCategory.id}/`, updatedCategory, {
          params: { user: storedUserId, company: storedCompanyId },
        })
        .then(() => {
          setCategories(
            categories.map((cat) =>
              cat.id === updatedCategory.id ? updatedCategory : cat
            )
          );
          handleCloseModal();
        })
        .catch((error) => console.error("Error updating category", error));
    }
  };

  const handleAdd = () => {
    navigate("/addcategory");
  };

  const sortedCategories = [...categories]
    .map((category) => ({
      ...category,
      active: JSON.stringify(category.active), // Convert `active` to string
    }))
    .sort((a, b) => {
      if (sortedField) {
        const comparison = a[sortedField] > b[sortedField] ? 1 : -1;
        return sortDirection === "asc" ? comparison : -comparison;
      }
      return 0;
    });

  const filteredCategories = sortedCategories.filter(
    (category) =>
      category.english_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedCategories = filteredCategories.slice(0, pageSize);

  const columns = [
    { headerName: t("categories.code"), field: "code", sortable: true },
    { headerName: t("categories.name"), field: "english_name", sortable: true },
    { headerName: t("categories.type"), field: "type", sortable: true },
    {
      headerName: t("categories.status"),
      field: "active",
      sortable: true,
      valueFormatter: (params) =>
        params.value === "true"
          ? t("categories.active")
          : t("categories.inactive"),
    },
    {
      headerName: t("categories.actions"),
      cellRenderer: (params) => (
        <div>
          <ViewBtn onClick={() => handleOpenModal(params.data, false)} />
          <EditBtn onClick={() => handleOpenModal(params.data, true)} />
        </div>
      ),
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div dir={i18n.dir()}>
      <Header />
      <UserSideBar />
      <main
        id="main"
        className="main"
        // style={{
        //   marginRight: i18n.dir() === "rtl" ? "250px" : "0", // Adjust the value as needed
        // }}
      >
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>{t("categories.title")}</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          {t("categories.rowsPerPage")}: {pageSize}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPageSize(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        data={categories}
                        className="btn btn-primary mx-3"
                        filename={"categories.csv"}
                        style={{ width: "36%" }}
                      >
                        {t("categories.exportCSV")}
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder={t("categories.search")}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Add Category Button */}
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="float-right"
                    >
                      {t("categories.addCategory")}
                    </Button>
                  </div>

                  {/* AG Grid Table */}
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 400, width: "100%" }}
                    dir={i18n.dir()}
                  >
                    <AgGridReact
                      rowData={paginatedCategories}
                      columnDefs={columns}
                      pagination
                      paginationPageSize={pageSize}
                      onGridReady={(params) => params.api.sizeColumnsToFit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Category Modal */}
      <CategoryModal
        show={isModalVisible}
        handleClose={handleCloseModal}
        categoryData={selectedCategory}
        isEditMode={isEditMode}
        onSave={handleSaveCategory}
      />
    </div>
  );
};

export default ViewCategories;
