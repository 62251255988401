import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv'; // For exporting CSV
import { Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import AccountModal from './AccountModal'; // Modal for viewing/updating accounts

const ViewAccount = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isEditMode, setIsEditMode] = useState(''); // Modal mode

  const navigate = useNavigate();
  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  // Fetch accounts on component mount
  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axiosInstance.get('/api/accounts/', {
        params: { user: storedUserId, company: storedCompanyId }
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching accounts', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter(item => {
    return (
      item.account_name.toLowerCase().includes(search.toLowerCase()) ||
      item.account_code.toLowerCase().includes(search.toLowerCase()) ||
      item.account_type.toLowerCase().includes(search.toLowerCase()) ||
      item.account_subtype.toLowerCase().includes(search.toLowerCase())
    );
  });

  const handleShowModal = async (id, editMode) => {
    setIsEditMode(editMode);
    console.log("handleShowModal", editMode);

    try {
      if (editMode === 'view') {
        // Fetch the full account data if viewing
        const response = await axiosInstance.get(`/api/accounts/${id}/`, {
          params: { user: storedUserId, company: storedCompanyId }
        });
        setSelectedAccount(response.data);
      } else {
        // For editing, just set the account ID
        const account = data.find(item => item.id === id);
        setSelectedAccount(account);
      }
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching account details', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAccount(null);
    fetchAccounts(); // Refresh data after closing the modal
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Account List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={data}
                        filename={"account-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    {/* Add Account Button */}
                    <Button variant="primary" onClick={() => navigate('/add-account')} className="float-right">
                      Add Account
                    </Button>
                  </div>

                  {/* Table */}
                  <table className="table table-striped text-center">
                    <thead>
                      <tr>
                        <th>Account Code</th>
                        <th>Account Name</th>
                        <th>Type</th>
                        <th>Subtype</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((item) => (
                        <tr key={item.id}>
                          <td>{item.account_code}</td>
                          <td>{item.account_name}</td>
                          <td>{item.account_type}</td>
                          <td>{item.account_subtype}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, 'view')}
                            >
                              View Details
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShowModal(item.id, 'edit')}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredData.length === 0 && (
                    <p className="text-center">No accounts available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Account Modal */}
      <AccountModal
        show={showModal}
        handleClose={handleCloseModal}
        account={selectedAccount} // Pass the account data to AccountModal
        mode={isEditMode ? 'edit' : 'view'} // Pass the mode to AccountModal
      />
    </div>
  );
};

export default ViewAccount;
