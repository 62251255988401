import React, { useEffect, useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { useNavigate } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { CSVLink } from 'react-csv'; // For exporting CSV
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const InventoryListView = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    fetchInventory();
  }, []);

  const fetchInventory = async () => {
    const userId = localStorage.getItem('user_id');
    const companyId = localStorage.getItem('company_id');
    try {
      const response = await axiosInstance.get('/api/inventory/',{
        params: { company: companyId } // Fixed typo in parameter
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching inventory data', error);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = data.filter(item => {
    return (
      item.raw_material.code.toLowerCase().includes(search.toLowerCase()) ||
      item.raw_material.name.toLowerCase().includes(search.toLowerCase()) ||
      item.warehouse.english_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Inventory List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                        {/* Export CSV Button */}
                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={data}
                        filename={"client-supplier-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>
                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50 mx-3"
                        placeholder="Search by code, name, or warehouse"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                  </div>

                  {/* Table */}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Warehouse</th>
                        <th>Quantity</th>
                        <th>Unit Price</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map((item) => (
                        <tr key={item.id}>
                          <td>{item.raw_material.code}</td>
                          <td>{item.raw_material.name}</td>
                          <td>{item.warehouse.english_name}</td>
                          <td>{item.quantity}</td>
                          <td>${item.raw_material.unit_price}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => navigate(`/edit-inventory/${item.id}`)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => {
                                if (window.confirm('Are you sure you want to delete this item?')) {
                                  axiosInstance.delete(`/api/inventory/${item.id}/`)
                                    .then(() => {
                                      setData(data.filter((i) => i.id !== item.id));
                                    })
                                    .catch((error) => console.error('Error deleting item', error));
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                      {filteredData.length === 0 && (
                        <tr>
                          <td colSpan="6" className="text-center">No inventory items available.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default InventoryListView;
