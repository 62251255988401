import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const BankAccountModal = ({ show, handleClose, bankAccount, mode }) => {
  const [name, setName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [balance, setBalance] = useState(0.0);
  const [account, setAccount] = useState('');
  const [accountsList, setAccountsList] = useState([]); // State to store accounts list
  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  useEffect(() => {
    // Fetch the list of accounts when the component mounts
    const fetchAccounts = async () => {
      try {
        const response = await axiosInstance.get('/api/accounts/', {
          params: { user: storedUserId, company: storedCompanyId }
        });
        setAccountsList(response.data); // Assuming response.data is the list of accounts
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();

    // Set form fields if editing or viewing an existing bank account
    if (bankAccount && mode !== 'create') {
      setName(bankAccount.name);
      setAccountNumber(bankAccount.account_number);
      setBalance(bankAccount.balance);
      setAccount(bankAccount.account); // Assuming `account` is an ID reference to another model
    } else {
      resetForm();
    }
  }, [bankAccount, mode]);

  const resetForm = () => {
    setName('');
    setAccountNumber('');
    setBalance(0.0);
    setAccount('');
  };

  const handleSave = async () => {
    try {
      const payload = {
        name: name,
        account_number: accountNumber,
        balance: balance,
        account: account,
        company: storedCompanyId, // Include company_id
        user: storedUserId // Include user_id
      };

      if (mode === 'edit' && bankAccount) {
        await axiosInstance.put(`/api/bank-accounts/${bankAccount.id}/`, payload, {
          params: { user: storedUserId, company: storedCompanyId }
        });
      } else {
        await axiosInstance.post('/api/bank-accounts/', payload, {
          params: { user: storedUserId, company: storedCompanyId }
        });
      }

      handleClose();
    } catch (error) {
      console.error('Error saving bank account:', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === 'edit' ? 'Edit Bank Account' : mode === 'view' ? 'View Bank Account' : 'Add Bank Account'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="bankAccountName">
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={mode === 'view'} // Disable in view mode
            />
          </Form.Group>

          <Form.Group controlId="bankAccountNumber" className="mt-3">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter account number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              disabled={mode === 'view'} // Disable in view mode
            />
          </Form.Group>

          <Form.Group controlId="bankAccountBalance" className="mt-3">
            <Form.Label>Balance</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter balance"
              value={balance}
              onChange={(e) => setBalance(parseFloat(e.target.value))}
              disabled={mode === 'view'} // Disable in view mode
            />
          </Form.Group>

          <Form.Group controlId="bankAccount" className="mt-3">
            <Form.Label>Account</Form.Label>
            <Form.Control
              as="select"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              disabled={mode === 'view'} // Disable in view mode
            >
              <option value="">Select an account</option>
              {accountsList.map((acc) => (
                <option key={acc.id} value={acc.account_code}>
                  {acc.account_name} {/* Adjust as needed based on the account properties */}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {mode === 'view' ? 'Close' : 'Cancel'}
        </Button>
        {mode !== 'view' && (
          <Button variant="primary" onClick={handleSave}>
            {mode === 'edit' ? 'Update' : 'Add'}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default BankAccountModal;
