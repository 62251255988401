import React from "react";
import { useTranslation } from "react-i18next";
import arFlag from "../assets/img/ksaflag.png"; // Arabic flag
import enFlag from "../assets/img/ukflag.png"; // English flag

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const switchLanguage = (lang) => {
    i18n.changeLanguage(lang);
    document.body.dir = lang === "ar" ? "rtl" : "ltr"; // Adjust direction for RTL/LTR
  };

  return (
    <div>
      {currentLanguage === "en" ? (
        <img
          src={arFlag}
          alt="Switch to Arabic"
          style={{ cursor: "pointer", width: "30px", height: "30px" }}
          onClick={() => switchLanguage("ar")}
        />
      ) : (
        <img
          src={enFlag}
          alt="Switch to English"
          style={{ cursor: "pointer", width: "30px", height: "30px" }}
          onClick={() => switchLanguage("en")}
        />
      )}
    </div>
  );
};

export default LanguageSwitcher;
