import React, { useState, useEffect } from "react";
import Axios from "axios";
import PlanModal from "./PlanModal"; // Assuming you already have a PlanModal component
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/PlanModal.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import logo from "../assets/img/logo1.png";
import Header from "../master-layout/Header";
import AdminSidebar from "../master-layout/AdminSidebar";
import axiosInstance from "../utils/axiosInstance";

const Plans = () => {
  const [plans, setPlans] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleOpenPlanModal = () => setShowModal(true);
  const handleClosePlanModal = () => setShowModal(false);

  useEffect(() => {
    // Fetch plans from backend
    fetchPlans();
  }, []);

  const fetchPlans = () => {
    axiosInstance.get("/api/plans/")
      .then((response) => {
        setPlans(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the plans!", error);
      });
  };

  const handleAddPlan = (newPlan) => {
    // Add the new plan to the plans array
    setPlans([...plans, newPlan]);
  };

  return (
    <div>
      {/* Header and Sidebar */}
      <Header />
      <AdminSidebar />

      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Plans</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active">Plans</li>
            </ol>
          </nav>
        </div>

        <section className="section dashboard">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-end mb-3">
                    <button
                      className="btn btn-primary d-flex align-items-center justify-content-center"
                      onClick={handleOpenPlanModal}
                      style={{ fontSize: "24px", marginTop: "10px", width: "50px", height: "50px", padding: 0 }}
                    >
                      <i className="bi bi-plus"></i>
                    </button>
                  </div>

                  <div className="row">
                    {plans.map((plan) => (
                      <div className="col-md-4 mb-4" key={plan.id}>
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title">{plan.name}</h5>
                            <p className="card-text">
                              <strong>Modules: </strong>
                              {Object.keys(plan.plan_modules)
                                .filter((module) => plan.plan_modules[module])
                                .join(", ")}
                            </p>
                            <p className="card-text"><strong>Price: </strong>${plan.price}</p>
                            <p className="card-text"><strong>Duration: </strong>{plan.duration}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <PlanModal show={showModal} handleClose={handleClosePlanModal} onAddPlan={handleAddPlan} />
    </div>
  );
};

export default Plans;
