import React, { useState } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import 'bootstrap/dist/css/bootstrap.min.css';

const AddWarehouse = () => {
  const [formData, setFormData] = useState({
    english_name: '',
    arabic_name: '',
    code: '',
    address: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
  });

  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const companyId = localStorage.getItem('company_id');
    const userId = localStorage.getItem('user_id');

    if (!companyId || !userId) {
      setErrorMessage('User or Company ID not found in localStorage.');
      return;
    }

    const data = { ...formData, company: companyId, user: userId };

    axiosInstance.post('/api/warehouses/', data)
      .then((response) => {
        console.log('Warehouse added:', response.data);
        navigate('/warehouses');  // Redirect to the warehouse list view
      })
      .catch((error) => {
        console.error('Error adding warehouse:', error);
        setErrorMessage('Error adding warehouse. Please try again.');
      });
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Warehouse</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">English Name</label>
                        <input
                          type="text"
                          name="english_name"
                          value={formData.english_name}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">Arabic Name</label>
                        <input
                          type="text"
                          name="arabic_name"
                          value={formData.arabic_name}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">Code</label>
                        <input
                          type="text"
                          name="code"
                          value={formData.code}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">Address</label>
                        <textarea
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">City</label>
                        <input
                          type="text"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">State</label>
                        <input
                          type="text"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">Zip Code</label>
                        <input
                          type="text"
                          name="zip_code"
                          value={formData.zip_code}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">Country</label>
                        <input
                          type="text"
                          name="country"
                          value={formData.country}
                          onChange={handleChange}
                          className="form-control"
                          required
                        />
                      </div>
                    </div>

                    {errorMessage && (
                      <div className="alert alert-danger" role="alert">
                        {errorMessage}
                      </div>
                    )}

                    <div className="text-center">
                      <button type="submit" className="btn btn-primary">Add Warehouse</button>
                      <button type="reset" className="btn btn-secondary mx-2">Reset</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddWarehouse;
