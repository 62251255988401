import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { Dropdown, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CSVLink } from "react-csv";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import EditBtn from "../../ui-kit/TableActionBtns/EditBtn.jsx";
import ViewBtn from "../../ui-kit/TableActionBtns/ViewBtn.jsx";

const ViewInvoicesCredit = () => {
  const [invoicesCredit, setInvoicesCredit] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(10); // Pagination size
  const navigate = useNavigate();
  const [selectedInvoiceCredit, setSelectedInvoiceCredit] = useState(null);

  const storedUserId = localStorage.getItem("user_id");
  const storedCompanyId = localStorage.getItem("company_id");

  useEffect(() => {
    if (storedUserId && storedCompanyId) {
      axiosInstance
        .get("/api/categories/", {
          params: { user: storedUserId, company: storedCompanyId },
        })
        .then((response) => setInvoicesCredit(response.data))
        .catch((error) =>
          console.error("Error fetching invoices debit notes", error)
        );
    } else {
      console.error("User or company ID not found in localStorage");
    }
  }, []);

  const handleAdd = () => {
    navigate("/addInvoiceCreditNote");
  };

  const sortedInvoicesCredit = [...invoicesCredit].sort((a, b) => {
    if (sortedField) {
      const comparison = a[sortedField] > b[sortedField] ? 1 : -1;
      return sortDirection === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const filteredInvoicesCredit = sortedInvoicesCredit.filter(
    (invoicesCredit) =>
      invoicesCredit.code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      invoicesCredit.invoiceCode
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  const paginatedInvoicesCredit = filteredInvoicesCredit.slice(0, pageSize);

  const columns = [
    { headerName: "Code", field: "code", sortable: true },
    { headerName: "Invoice Code", field: "invoiceCode", sortable: true },
    { headerName: "Customer", field: "customer", sortable: true },
    { headerName: "Date", field: "date", sortable: true },
    { headerName: "Amount", field: "amount", sortable: true },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <div>
          {/* <ViewBtn onClick={() => handleOpenModal(params.data, false)} />
          <EditBtn onClick={() => handleOpenModal(params.data, true)} /> */}
        </div>
      ),
      sortable: false,
      filter: false,
    },
  ];

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Invoices Credit Notes</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {pageSize}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPageSize(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        data={invoicesCredit}
                        className="btn btn-primary mx-3"
                        filename={"invoicesCreditNotes.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Add Invoices Credit Note Button */}
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="float-right"
                    >
                      Add Invoices Credit Note
                    </Button>
                  </div>

                  {/* AG Grid Table */}
                  <div
                    className="ag-theme-alpine"
                    style={{ height: 400, width: "100%" }}
                  >
                    <AgGridReact
                      rowData={paginatedInvoicesCredit}
                      columnDefs={columns}
                      pagination
                      paginationPageSize={pageSize}
                      onGridReady={(params) => params.api.sizeColumnsToFit()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ViewInvoicesCredit;
