// src/i18n.js or wherever your i18n configuration is located

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./locales/en/translation.json"; // Adjust path as necessary
import arTranslation from "./locales/ar/translation.json"; // Adjust path as necessary

// Language resources
const resources = {
  en: {
    translation: enTranslation,
  },
  ar: {
    translation: arTranslation,
  },
};

// Get the initial language from localStorage or default to 'en'
const language = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: language, // Set the initial language
  fallbackLng: "en", // Fallback language if the current language is not available
  interpolation: {
    escapeValue: false, // React already safes from xss
  },
  react: {
    useSuspense: false, // Set to true if using Suspense
  },
});

// Save the selected language to localStorage when it changes
i18n.on("languageChanged", (lng) => {
  localStorage.setItem("language", lng);
});

export default i18n;
