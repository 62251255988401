import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../assets/css/style.css'; // Import your custom CSS
import logo from '../assets/img/logo1.png'; // Import the logo
import axiosInstance from '../utils/axiosInstance'; // Import your axios instance

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/api/login/', {
                email,
                password,
            });

            const { access, refresh, user_type, user, company, userName, companyName } = response.data;

            // Save tokens and user-related data to localStorage
            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);
            localStorage.setItem('user_type', user_type);
            localStorage.setItem('userName', userName);
            localStorage.setItem('company_name', companyName);
            localStorage.setItem('user_id', user);
            localStorage.setItem('company_id', company);

            // Navigate based on user type
            if (user_type === 'toimitekAdmin') {
                navigate('/admin-dashboard');
            } else if (user_type === 'company_user') {
                navigate('/dashboard');
            } else {
                setError('Invalid user type');
            }
        } catch (err) {
            setError('Invalid Email or password');
        }
    };

    return (
        <main>
            <div className="container">
                <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex justify-content-center py-4">
                                    <a href="#" className="logo d-flex align-items-center w-auto">
                                        <img src={logo} alt="Logo" />
                                    </a>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                            <p className="text-center small">Enter your username & password to login</p>
                                        </div>

                                        {/* Error Alert */}
                                        {error && (
                                            <div className="alert alert-danger" role="alert">
                                                {error}
                                            </div>
                                        )}

                                        <form className="row g-3 needs-validation" noValidate onSubmit={handleLogin}>
                                            <div className="col-12">
                                                <label htmlFor="yourUsername" className="form-label">Email</label>
                                                <div className="input-group has-validation">
                                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="yourUsername"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                    <div className="invalid-feedback">Please enter your Email.</div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <label htmlFor="yourPassword" className="form-label">Password</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="yourPassword"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                                <div className="invalid-feedback">Please enter your password!</div>
                                            </div>

                                            <div className="col-12">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="remember"
                                                        id="rememberMe"
                                                    />
                                                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <button className="btn btn-primary w-100" type="submit">Login</button>
                                            </div>

                                            <div className="col-12">
                                                <p className="small mb-0">Forget password : <a href="#">Please contact Your Admin</a></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div className="credits">
                                    Designed by <a href="#">Toimitek</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default Login;
