import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axiosInstance"; // Adjust the import path as needed
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import { Button, Dropdown, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import InvoiceModal from "./InvoiceModal"; // Import the modal component

const InvoicesView = () => {
  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [modalMode, setModalMode] = useState("view");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const storedCompanyId = localStorage.getItem("company_id");
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Fetch invoices data
    const fetchInvoices = async () => {
      try {
        const response = await axiosInstance.get("/api/invoices/", {
          params: { company: storedCompanyId },
        });
        setInvoices(response.data);
      } catch (error) {
        console.error("Error fetching invoices", error);
      }
    };

    fetchInvoices();
  }, [storedCompanyId]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = invoices.filter(
    (item) =>
      item.supplier.toLowerCase().includes(search.toLowerCase()) ||
      item.supplier_invoice_reference
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      item.issue_date.toLowerCase().includes(search.toLowerCase()) ||
      item.due_date.toLowerCase().includes(search.toLowerCase()) ||
      item.delivery_date.toLowerCase().includes(search.toLowerCase())
  );

  const handleAddClick = () => {
    navigate("/addInvoice"); // Navigate to the addInvoice page
  };

  const handleEditClick = (invoice) => {
    setModalMode("edit");
    setSelectedInvoice(invoice);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setModalShow(false);
    setSelectedInvoice(null);
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Invoices List</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows: {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>
                            15
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={filteredData}
                        filename={"invoices-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>

                    <Button
                      variant="primary"
                      onClick={handleAddClick}
                      className="float-right"
                    >
                      Add Invoice
                    </Button>
                  </div>

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Client Name</th>
                        <th>Issue Date</th>
                        <th>Due Date</th>
                        <th>Amount</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData
                        .slice(0, rowsPerPage)
                        .map((invoice, index) => (
                          <tr key={invoice.id}>
                            <td>{index + 1}</td>
                            <td>{invoice.code}</td>
                            <td>{invoice.client_name}</td>
                            <td>{invoice.issue_date}</td>
                            <td>{invoice.due_date}</td>
                            <td>{invoice.amount}</td>
                            <td>
                              <Button
                                variant="primary"
                                onClick={() => handleEditClick(invoice)}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>

                  {filteredData.length === 0 && (
                    <p className="text-center">No Invoices available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <InvoiceModal
        show={modalShow}
        handleClose={handleModalClose}
        invoice={selectedInvoice}
        mode={modalMode}
      />
    </div>
  );
};

export default InvoicesView;
