import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";

const AddBillCredit = () => {
  const [supplier, setSupplier] = useState("");
  const [supplierBill, setSupplierBill] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [selectedBillId, setSelectedBillId] = useState("");
  const [items, setItems] = useState([
    {
      id:"",
      raw_material: "",
      item_price: "",
      quantity: "",
      discount: "",
      tax: "",
      subtotal: "",
      total: "",
    },
  ]);
  const [raw_materials, setraw_materials] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [accounts, setAccounts] = useState([]); // New state for accounts
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);

    // Fetch raw materials
    axiosInstance
      .get("/api/raw-materials/", {
        params: { user: storedUserId, company: storedCompanyId },
      })
      .then((response) => {
        setraw_materials(response.data);
      })
      .catch((error) => {
        console.error("Error fetching raw materials", error);
      });

    // Fetch suppliers
    axiosInstance
      .get("/api/bills/", {
        params: { user: storedUserId, company: storedCompanyId},
      })
      .then((response) => {
        setSuppliers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
      });
    // Fetch suppliers bills
    
  }, []);

  const handleSupplierBillChange = async (event) => {
    const selectedId = event.target.value;
    setSelectedBillId(selectedId);
  
    // Fetch the selected supplier bill data
    try {
      const response = await axiosInstance.get("/api/bills/", {
        params: { bill_id: selectedId, user: userId, company: companyId },
      });
      // Set fetched bill data
      const billData = response.data[0];
      console.log("billData",billData);
      setSupplierBill(billData.supplier_bill_reference);

      setIssueDate(billData.issue_date || "");
      setCreatedDate(billData.created_date || "");
      setItems(billData.items || []);
    } catch (error) {
      console.error("Error fetching supplier bill", error);
    }
  };
  
  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        id:"",
        raw_material: "",
        item_price: "",
        quantity: "",
        discount: "",
        tax: "",
        subtotal: "",
        total: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!userId || !companyId) {
      console.error("User ID or Company ID is missing");
      return;
    }
  
    try {
      // Prepare data for Debit Note and Bill Items
      const totalAmount = items.reduce((sum, item) => sum + parseFloat(item.total), 0);
      const requestData = {
        company: companyId,
        user: userId,
        bill:selectedBillId,
        date: createdDate, // This maps to 'date' in the serializer
        amount: items.reduce((sum, item) => sum + parseFloat(item.total), 0), // Total amount of all items
        reason: "Added via credit note",
        supplier_bill_reference: supplierBill, // This is correct as it maps to 'supplier_bill_reference'
        bill_issue_date: issueDate, // This should match the 'bill_issue_date' field
         // Fetch the supplier's name from suppliers
        bill_items: items.map(item => ({
          id:item.id,
          bill:selectedBillId,
          raw_material: item.raw_material,
          item_price: item.item_price,
          quantity: item.quantity,
          discount: item.discount,
          tax: item.tax,
          sub_total: parseFloat(item.subtotal),
          total: parseFloat(item.total),
        })),
      };
      console.log(companyId, userId, requestData)
      // Post Debit Note with Bill Items
      const response = await axiosInstance.post("/api/debit-note/add/", requestData);
  
      // Handle success
      console.log("Success:", response.data);
      setSupplier("");
      setSupplierBill("");
      setIssueDate("");
      setCreatedDate("");
      setItems([
        {
          raw_material: "",
          item_price: "",
          quantity: "",
          discount: "",
          tax: "",
          subtotal: "",
          total: "",
        },
      ]);
      navigate("/bills");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting bill data", error);
    }
  };
  
  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Bill Credit Note</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="supplierBill" className="form-label">
                          Bill Number
                        </label>
                        <select
                          id="supplierBill"
                          className="form-select"
                          value={selectedBillId}
                          onChange={handleSupplierBillChange} // Updated handler
                          required
                        >
                          <option value="">Select Bill</option>
                          {suppliers.map((sup) => (
                            <option key={sup.id} value={sup.id}>
                              {sup.id}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="supplier" className="form-label">
                          Supplier
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="supplier"
                          value={supplierBill}
                          onChange={(e) => setSupplier(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="issueDate" className="form-label">
                          Issue Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="issueDate"
                          value={issueDate}
                          onChange={(e) => setIssueDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="createdDate" className="form-label">
                          Created Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="createdDate"
                          value={createdDate}
                          onChange={(e) => setCreatedDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Raw Material</th>
                              <th>Item Price</th>
                              <th>Quantity</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Subtotal</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    name="raw_material"
                                    className="form-select"
                                    value={item.raw_material}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  >
                                    <option value="">
                                      Select Raw Material
                                    </option>
                                    {raw_materials.map((raw) => (
                                      <option key={raw.id} value={raw.id}>
                                        {raw.english_name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="item_price"
                                    className="form-control"
                                    value={item.item_price}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    value={item.quantity}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="discount"
                                    className="form-control"
                                    value={item.discount}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="tax"
                                    className="form-control"
                                    value={item.tax}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="subtotal"
                                    className="form-control"
                                    value={item.subtotal}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="total"
                                    className="form-control"
                                    value={item.total}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAddItem}
                      >
                        Add Item
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button type="submit" className="btn btn-success">
                          Save Bill Credit Note
                        </button>
                      </div>
                    </div>
                    {error && (
                      <div className="alert alert-danger mt-3">{error}</div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddBillCredit;
