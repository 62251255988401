import React, { useState } from "react";
import axiosInstance from '../utils/axiosInstance';

import { Modal, Button, Form } from "react-bootstrap";

const PlanModal = ({ show, handleClose, onAddPlan }) => {
  const [planData, setPlanData] = useState({
    name: "",
    price: "",
    description: "",
    duration: "1M", // Default choice value corresponding to 1 month
    plan_modules: {
      CRM: false,
      POS: false,
      HR: false,
      Sales: false,
    },
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setPlanData((prevData) => ({
        ...prevData,
        plan_modules: {
          ...prevData.plan_modules,
          [name]: checked,
        },
      }));
    } else {
      setPlanData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handles form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Posting the form data to the backend API
    axiosInstance.post("/api/plans/", planData)
      .then((response) => {
        onAddPlan(response.data);  // Call the callback to add the new plan to the state
        handleClose();  // Close the modal on success
      })
      .catch((error) => {
        console.error("There was an error creating the plan!", error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Create Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={planData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={planData.price}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={planData.description}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Duration</Form.Label>
            <Form.Control
              as="select"
              name="duration"
              value={planData.duration}
              onChange={handleChange}
              required
            >
              <option value="1M">1 month</option>
              <option value="3M">3 months</option>
              <option value="6M">6 months</option>
              <option value="1Y''">1 year</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Modules</Form.Label>
            {Object.keys(planData.plan_modules).map((module) => (
              <Form.Check
                key={module}
                type="switch"  // Change from checkbox to switch (toggle)
                label={module}
                name={module}
                checked={planData.plan_modules[module]}
                onChange={handleChange}
              />
            ))}
          </Form.Group>
          <Button variant="primary" type="submit">
            Save Plan
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PlanModal;
