import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";

const AddCategory = () => {
  const [englishName, setEnglishName] = useState("");
  const [arabicName, setArabicName] = useState("");
  const [code, setCode] = useState("");
  const [type, setType] = useState("purchase");
  const [parentCategory, setParentCategory] = useState("");
  const [active, setActive] = useState(true);
  const [image, setImage] = useState(null);
  const [userId, setUserId] = useState(null); // State for userId
  const [companyId, setCompanyId] = useState(null); // State for companyId
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!userId || !companyId) {
      console.error("User ID or Company ID is missing");
      return;
    }

    const formData = new FormData();
    formData.append("english_name", englishName);
    formData.append("arabic_name", arabicName);
    formData.append("code", code);
    formData.append("type", type);
    formData.append("active", active);
    formData.append("company", companyId);
    formData.append("user", userId);

    if (parentCategory) {
      formData.append("parent_category", parentCategory);
    }

    if (image) {
      formData.append("image", image);
    }

    axiosInstance
      .post("/api/categories/", formData)
      .then((response) => {
        console.log("Category added successfully", response.data);
        navigate("/viewCategories");
      })
      .catch((error) => {
        console.error("Error adding category", error);
      });
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Category</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="englishName" className="form-label">
                          English Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="englishName"
                          value={englishName}
                          onChange={(e) => setEnglishName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="arabicName" className="form-label">
                          Arabic Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="arabicName"
                          value={arabicName}
                          onChange={(e) => setArabicName(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="code" className="form-label">
                          Code
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="code"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="type" className="form-label">
                          Type
                        </label>
                        <select
                          className="form-select"
                          id="type"
                          value={type}
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="purchase">Purchase</option>
                          <option value="sale">Sale</option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="parentCategory" className="form-label">
                          Parent Category
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="parentCategory"
                          value={parentCategory}
                          onChange={(e) => setParentCategory(e.target.value)}
                          placeholder="Parent Category ID (if any)"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="active" className="form-label">
                          Status
                        </label>
                        <select
                          className="form-select"
                          id="active"
                          value={active ? "true" : "false"}
                          onChange={(e) => setActive(e.target.value === "true")}
                        >
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="image" className="form-label">
                          Image
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddCategory;
