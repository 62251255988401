import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import ProductBills from "./ProductBills";

const mockProductBills = [
  {
    id: 1,
    name: "Product A",
    price: 100,
  },
  {
    id: 2,
    name: "Product B",
    price: 200,
  },
  {
    id: 3,
    name: "Product C",
    price: 300,
  },
];
const AddInvoice = () => {
  const [issueDate, setIssueDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [selectedReceivingAccounts, setSelectedReceivingAccounts] =
    useState("");

  const [items, setItems] = useState([
    {
      rawMaterial: "",
      item_price: "",
      quantity: "",
      discount: "",
      tax: "",
      subtotal: "",
      total: "",
    },
  ]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [paymentTypes, setpaymentTypes] = useState([]);
  const [receivingAccounts, setReceivingAccounts] = useState([]); // New state for accounts

  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);

    // Fetch customers
    axiosInstance
      .get("/api/customer/")
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching customers", error);
      });
    setRawMaterials(mockProductBills);
  }, []);

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];

    if (name === "rawMaterial") {
      const selectedProduct = rawMaterials.find((raw) => raw.id === value);
      if (selectedProduct) {
        // Update the row with the selected product details
        updatedItems[index] = {
          rawMaterial: selectedProduct.id,
          item_price: selectedProduct.price,
          quantity: 1,
          discount: 0,
          tax: 0,
          subtotal: selectedProduct.price,
          total: selectedProduct.price,
        };
      }
    } else {
      // Update other fields as usual
      updatedItems[index] = { ...updatedItems[index], [name]: value };
      if (name === "quantity") {
        updatedItems[index].subtotal = updatedItems[index].item_price * value;
        updatedItems[index].total =
          updatedItems[index].subtotal -
          updatedItems[index].discount +
          updatedItems[index].tax;
      } else if (name === "discount" || name === "tax") {
        updatedItems[index].subtotal =
          updatedItems[index].quantity * updatedItems[index].item_price;
        updatedItems[index].total =
          updatedItems[index].subtotal -
          updatedItems[index].discount +
          updatedItems[index].tax;
      }
    }

    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        rawMaterial: "",
        item_price: "",
        quantity: "",
        discount: "",
        tax: "",
        subtotal: "",
        total: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };
  const handleProductClick = (product) => {
    const itemIndex = items.findIndex(
      (item) => item.rawMaterial === product.id
    );

    if (itemIndex > -1) {
      // Item already exists, update quantity
      const updatedItems = [...items];
      updatedItems[itemIndex].quantity += 1;
      updatedItems[itemIndex].subtotal =
        updatedItems[itemIndex].item_price * updatedItems[itemIndex].quantity;
      updatedItems[itemIndex].total =
        updatedItems[itemIndex].subtotal -
        updatedItems[itemIndex].discount +
        updatedItems[itemIndex].tax;
      setItems(updatedItems);
    } else {
      // Item does not exist, add a new row
      setItems([
        ...items,
        {
          rawMaterial: product.id,
          item_price: product.price,
          quantity: 1,
          discount: 0,
          tax: 0,
          subtotal: product.price,
          total: product.price,
        },
      ]);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userId || !companyId) {
      console.error("User ID or Company ID is missing");
      return;
    }

    try {
      // Post Bill
      const response = await axiosInstance.post(
        "/api/bills/create/",
        {
          invoice_number: invoiceNumber,
          issue_date: issueDate,
          due_date: dueDate,
          delivery_date: deliveryDate,
          customer: selectedCustomer,
          category: selectedCategory,
          payment_type: selectedPaymentType,
          receiving_account: selectedReceivingAccounts,
          company: companyId,
        },
        {
          params: {
            company: companyId,
            user: userId,
          },
        }
      );

      const billId = response.data.id; // Ensure `id` is the correct field in your response

      if (!billId) {
        console.error("Bill ID is not returned from API");
        return;
      }

      // Post Bill Items
      await Promise.all(
        items.map((item) =>
          axiosInstance.post(
            "/api/bill-items/create/",
            {
              bill: billId,
              raw_material: item.rawMaterial,
              item_price: item.item_price,
              quantity: item.quantity,
              discount: item.discount,
              tax: item.tax,
              company: companyId,
              sub_total: parseFloat(item.subtotal),
              total: parseFloat(item.total),
            },
            {
              params: {
                company: companyId,
                user: userId,
              },
            }
          )
        )
      );

      // Handle success
      setSelectedCustomer("");
      setInvoiceNumber("");
      setIssueDate("");
      setDueDate("");
      setDeliveryDate("");
      setSelectedCategory("");
      setSelectedPaymentType("");
      setSelectedReceivingAccounts("");
      setItems([
        {
          rawMaterial: "",
          item_price: "",
          quantity: "",
          discount: "",
          tax: "",
          subtotal: "",
          total: "",
        },
      ]);
      navigate("/Invoices");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting bill data", error);
    }
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Invoice</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <label htmlFor="customer" className="form-label">
                          Customer
                        </label>
                        <select
                          id="customer"
                          className="form-select"
                          value={selectedCustomer}
                          onChange={(e) => setSelectedCustomer(e.target.value)}
                          required
                        >
                          <option value="">Select Customer</option>
                          {customers.map((cus) => (
                            <option key={cus.id} value={cus.id}>
                              {cus.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="issueDate" className="form-label">
                          Issue Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="issueDate"
                          value={issueDate}
                          onChange={(e) => setIssueDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="dueDate" className="form-label">
                          Due Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="dueDate"
                          value={dueDate}
                          onChange={(e) => setDueDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="deliveryDate" className="form-label">
                          Delivery Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="deliveryDate"
                          value={deliveryDate}
                          onChange={(e) => setDeliveryDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-2 mb-3">
                        <label htmlFor="invoiceNumber" className="form-label">
                          Invoice Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="invoiceNumber"
                          value={invoiceNumber}
                          // onChange={(e) => setPurchaseOrder(e.target.value)}
                          readOnly
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="category" className="form-label">
                          Category
                        </label>
                        <select
                          id="category"
                          className="form-select"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          required
                        >
                          <option value="">Select Category</option>
                          {customers.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label htmlFor="paymentType" className="form-label">
                          Payment Type
                        </label>
                        <select
                          id="paymentType"
                          className="form-select"
                          value={selectedPaymentType}
                          onChange={(e) =>
                            setSelectedPaymentType(e.target.value)
                          }
                          required
                        >
                          <option value="">Select Payment Type</option>
                          {paymentTypes.map((payType) => (
                            <option key={payType.id} value={payType.id}>
                              {payType.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 mb-3">
                        <label
                          htmlFor="receivingAccount"
                          className="form-label"
                        >
                          Receiving Account
                        </label>
                        <select
                          id="receivingAccount"
                          className="form-select"
                          value={selectedReceivingAccounts}
                          onChange={(e) =>
                            setSelectedReceivingAccounts(e.target.value)
                          }
                          required
                        >
                          <option value="">Select Receiving Account </option>
                          {receivingAccounts.map((recAcc) => (
                            <option key={recAcc.id} value={recAcc.id}>
                              {recAcc.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12 mb-3">
                        <ProductBills
                          selectedCategory={selectedCategory}
                          onProductClick={handleProductClick}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Raw Material</th>
                              <th>Item Price</th>
                              <th>Quantity</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Subtotal</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    name="rawMaterial"
                                    className="form-select"
                                    value={item.rawMaterial}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  >
                                    <option value="">
                                      Select Raw Material
                                    </option>
                                    {rawMaterials.map((raw) => (
                                      <option key={raw.id} value={raw.id}>
                                        {raw.name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="item_price"
                                    className="form-control"
                                    value={item.item_price}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    value={item.quantity}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="discount"
                                    className="form-control"
                                    value={item.discount}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="tax"
                                    className="form-control"
                                    value={item.tax}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="subtotal"
                                    className="form-control"
                                    value={item.subtotal}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="total"
                                    className="form-control"
                                    value={item.total}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAddItem}
                      >
                        Add Item
                      </button>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button type="submit" className="btn btn-success">
                          Save Invoice
                        </button>
                      </div>
                    </div>
                    {error && (
                      <div className="alert alert-danger mt-3">{error}</div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddInvoice;
