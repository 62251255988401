import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance'; // Adjust the import path as needed

const BillModal = ({ show, handleClose, bill, mode }) => {
  const [supplier, setSupplier] = useState('');
  const [supplierBillReference, setSupplierBillReference] = useState('');
  const [issueDate, setIssueDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [purchaseOrder, setPurchaseOrder] = useState('');
  const [suppliers, setSuppliers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch suppliers
    const fetchSuppliers = async () => {
      try {
        const response = await axiosInstance.get('/api/suppliers/'); // Adjust the endpoint as needed
        setSuppliers(response.data);
      } catch (error) {
        console.error('Error fetching suppliers', error);
      }
    };

    fetchSuppliers();
  }, []);

  useEffect(() => {
    if (mode === 'edit' && bill) {
      setSupplier(bill.supplier || '');
      setSupplierBillReference(bill.supplier_bill_reference || '');
      setIssueDate(bill.issue_date || '');
      setDueDate(bill.due_date || '');
      setDeliveryDate(bill.delivery_date || '');
      setPurchaseOrder(bill.purchase_order || '');
    }
  }, [bill, mode]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (mode === 'create') {
        await axiosInstance.post('/api/bills/', {
          supplier,
          supplier_bill_reference: supplierBillReference,
          issue_date: issueDate,
          due_date: dueDate,
          delivery_date: deliveryDate,
          purchase_order: purchaseOrder,
          company: localStorage.getItem('company_id')
        });
      } else if (mode === 'edit') {
        await axiosInstance.put(`/api/bills/${bill.id}/`, {
          supplier,
          supplier_bill_reference: supplierBillReference,
          issue_date: issueDate,
          due_date: dueDate,
          delivery_date: deliveryDate,
          purchase_order: purchaseOrder
        });
      }
      handleClose();
    } catch (error) {
      setError('An error occurred. Please try again.');
      console.error('Error submitting bill data', error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'create' ? 'Create Bill' : 'Edit Bill'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="supplier">
            <Form.Label>Supplier</Form.Label>
            <Form.Control
              as="select"
              value={supplier}
              onChange={(e) => setSupplier(e.target.value)}
              required
            >
              <option value="">Select Supplier</option>
              {suppliers.map(s => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="supplierBillReference">
            <Form.Label>Supplier Bill Reference</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter supplier bill reference"
              value={supplierBillReference}
              onChange={(e) => setSupplierBillReference(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="issueDate">
            <Form.Label>Issue Date</Form.Label>
            <Form.Control
              type="date"
              value={issueDate}
              onChange={(e) => setIssueDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="dueDate">
            <Form.Label>Due Date</Form.Label>
            <Form.Control
              type="date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="deliveryDate">
            <Form.Label>Delivery Date</Form.Label>
            <Form.Control
              type="date"
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="purchaseOrder">
            <Form.Label>Purchase Order</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter purchase order number"
              value={purchaseOrder}
              onChange={(e) => setPurchaseOrder(e.target.value)}
              required
            />
          </Form.Group>

          {error && <p className="text-danger">{error}</p>}

          <Button variant="primary" type="submit">
            {mode === 'create' ? 'Create' : 'Save Changes'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BillModal;
