import React from "react";
import { useLocation, Link } from "react-router-dom"; // Import useLocation from react-router-dom
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../assets/css/style.css"; // Import your custom CSS

const AdminSidebar = () => {
  const location = useLocation(); // Get current path

  // Function to check if a link is active
  const isActive = (path) => location.pathname === path;

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* Dashboard Link */}
        <li
          className={`nav-item ${isActive("/admin-dashboard") ? "active" : ""}`}
        >
          <Link className="nav-link" to="/admin-dashboard">
            <i className="bi bi-grid"></i>
            <span>Dashboard</span>
          </Link>
        </li>

        {/* Plans Link */}
        <li className={`nav-item ${isActive("/plans") ? "active" : ""}`}>
          <Link className="nav-link" to="/plans">
            <i className="bi bi-bag"></i>
            <span>Plan</span>
          </Link>
        </li>

        {/* Users Link */}
        <li className={`nav-item ${isActive("/company") ? "active" : ""}`}>
          <Link className="nav-link" to="/company">
            <i className="bi bi-people"></i>
            <span>Users</span>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default AdminSidebar;
