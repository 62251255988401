import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axiosInstance';
import UserSideBar from "../../master-layout/UserSideBar";
import Header from '../../master-layout/Header';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv'; // For exporting CSV
import { Button, Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const WarehouseView = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    const companyId = localStorage.getItem('company_id');

    if (userId && companyId) {
      axiosInstance.get('/api/warehouses/', {
        params: { user: userId, company: companyId }
      })
      .then((response) => setWarehouses(response.data))
      .catch((error) => console.error('Error fetching warehouses', error));
    } else {
      console.error('User or company ID not found in localStorage');
    }
  }, []);

  const handleEdit = (id) => {
    navigate(`/warehouses/edit/${id}`);
  };

  const handleDelete = (id) => {
    axiosInstance.delete(`/api/warehouses/${id}/`)
      .then(() => {
        console.log(`Deleted warehouse with ID ${id}`);
        setWarehouses(warehouses.filter(warehouse => warehouse.id !== id));
      })
      .catch((error) => console.error('Error deleting warehouse', error));
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredData = warehouses.filter(warehouse => {
    return (
      warehouse.code.toLowerCase().includes(search.toLowerCase()) ||
      warehouse.english_name.toLowerCase().includes(search.toLowerCase()) ||
      warehouse.address.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Warehouses</h1>
          <Button
            variant="primary"
            onClick={() => navigate('/addWarehouseView')}
          >
            Add Warehouse
          </Button>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {rowsPerPage}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setRowsPerPage(5)}>5</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(10)}>10</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(15)}>15</Dropdown.Item>
                          <Dropdown.Item onClick={() => setRowsPerPage(20)}>20</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        className="btn btn-primary mx-3"
                        data={warehouses}
                        filename={"warehouses-list.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search by code, name, or address"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>

                  {/* Table */}
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Code</th>
                        <th scope="col">Name</th>
                        <th scope="col">Address</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.slice(0, rowsPerPage).map(warehouse => (
                        <tr key={warehouse.id}>
                          <td>{warehouse.code}</td>
                          <td>{warehouse.english_name}</td>
                          <td>{warehouse.address}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleEdit(warehouse.id)}
                            >
                              View
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleEdit(warehouse.id)}
                            >
                              Update
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDelete(warehouse.id)}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {filteredData.length === 0 && (
                    <p className="text-center">No warehouses available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default WarehouseView;
