import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";

const AddInvoiceCredit = () => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [customer, setCustomer] = useState("");
  const [invoiceIssuedDate, setInvoiceIssuedDate] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [invoiceDebitNoteNummber, setInvoiceDebitNoteNummber] = useState("");
  const [items, setItems] = useState([
    {
      rawMaterial: "",
      item_price: "",
      quantity: "",
      subtotal: "",
      tax: "",
      total: "",
    },
  ]);
  const [returnSummary, setReturnSummary] = useState([
    {
      return_amount: "",
      tax: "",
      total: "",
    },
  ]);
  const [invoices, setInvoices] = useState([]);
  const [returnItemsSubtotal, setReturnItemsSubtotal] = useState([]);
  const [returnItemsTax, setReturnItemsTax] = useState([]);
  const [returnItemsTotal, setReturnItemsTotal] = useState([]);
  const [returnSummarySubtotal, setReturnSummarySubtotal] = useState([]);
  const [returnSummaryTax, setReturnSummaryTax] = useState([]);
  const [returnSummaryTotal, setReturnSummaryTotal] = useState([]);
  const [rawMaterials, setRawMaterials] = useState([]);
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve user and company info from localStorage
    const storedUserId = localStorage.getItem("user_id");
    const storedCompanyId = localStorage.getItem("company_id");

    console.log("Retrieved userId:", storedUserId); // Debugging output
    console.log("Retrieved companyId:", storedCompanyId); // Debugging output

    setUserId(storedUserId);
    setCompanyId(storedCompanyId);

    // Fetch invoices
    axiosInstance
      .get("/api/invoices/")
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching suppliers", error);
      });
  }, []);

  const handleItemChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...items];
    updatedItems[index] = { ...updatedItems[index], [name]: value };
    setItems(updatedItems);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        rawMaterial: "",
        item_price: "",
        quantity: "",
        subtotal: "",
        tax: "",
        total: "",
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!userId || !companyId) {
      console.error("User ID or Company ID is missing");
      return;
    }

    try {
      // Post Bill
      const response = await axiosInstance.post(
        "/api/bills/create/",
        {
          customer,
          invoice_debit_note_nummber: invoiceDebitNoteNummber,
          invoice_issued_date: invoiceIssuedDate,
          created_date: createdDate,
          company: companyId,
        },
        {
          params: {
            company: companyId,
            user: userId,
          },
        }
      );

      const invoiceDebitNoteId = response.data.id; // Ensure `id` is the correct field in your response

      if (!invoiceDebitNoteId) {
        console.error("Bill ID is not returned from API");
        return;
      }

      // Post Bill Items
      await Promise.all(
        items.map((item) =>
          axiosInstance.post(
            "/api/bill-items/create/",
            {
              invoice_debit_note_id: invoiceDebitNoteId,
              raw_material: item.rawMaterial,
              item_price: item.item_price,
              quantity: item.quantity,
              discount: item.discount,
              tax: item.tax,
              company: companyId,
              sub_total: parseFloat(item.subtotal),
              total: parseFloat(item.total),
            },
            {
              params: {
                company: companyId,
                user: userId,
              },
            }
          )
        )
      );

      // Handle success
      setCustomer(""); // Corresponds to setSupplier("")
      setInvoiceDebitNoteNummber(""); // Corresponds to setSupplierBillReference("")
      setInvoiceIssuedDate(""); // Corresponds to setIssueDate("")
      setCreatedDate(""); // Corresponds to setDueDate("") - Assuming this is the due date in your new logic
      setCreatedDate(""); // Corresponds to setDeliveryDate("") - Assuming delivery date maps to created date
      setInvoiceNumber(""); // Corresponds to setPurchaseOrder("") - Mapping invoice number to purchase order

      // You might want to handle account information separately if needed.
      // If there are specific fields for accountFrom and accountTo, you may map them accordingly
      setItems([
        {
          rawMaterial: "",
          item_price: "",
          quantity: "",
          subtotal: "",
          tax: "",
          total: "",
        },
      ]);
      navigate("/viewInvoicesCredit");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error submitting bill data", error);
    }
  };

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Add Invoice Credit Note</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label htmlFor="invoiceNumber" className="form-label">
                          Invoice Number
                        </label>
                        <select
                          id="invoiceNumber"
                          className="form-select"
                          value={invoiceNumber}
                          onChange={(e) => setInvoiceNumber(e.target.value)}
                          required
                        >
                          <option value="">Select Invoice</option>
                          {invoices.map((invNo) => (
                            <option key={invNo.id} value={invNo.id}>
                              {invNo.english_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="customer" className="form-label">
                          Customer
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="customer"
                          value={customer}
                          onChange={(e) => setCustomer(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label
                          htmlFor="invoiceIssuedDate"
                          className="form-label"
                        >
                          Invoice Issue Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="invoiceIssuedDate"
                          value={invoiceIssuedDate}
                          onChange={(e) => setInvoiceIssuedDate(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="purchaseOrder" className="form-label">
                          Invoice Debit Note Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="invoiceDebitNoteNumber"
                          value={invoiceDebitNoteNummber}
                          onChange={(e) =>
                            setInvoiceDebitNoteNummber(e.target.value)
                          }
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label htmlFor="createdDate" className="form-label">
                          Created Date
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="createdDate"
                          value={createdDate}
                          onChange={(e) => setCreatedDate(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Return Items</th>
                              <th>Item Price</th>
                              <th>Quantity</th>
                              <th>Discount</th>
                              <th>Tax</th>
                              <th>Subtotal</th>
                              <th>Total</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <select
                                    name="rawMaterial"
                                    className="form-select"
                                    value={item.rawMaterial}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  >
                                    <option value="">
                                      Select Return Items
                                    </option>
                                    {rawMaterials.map((raw) => (
                                      <option key={raw.id} value={raw.id}>
                                        {raw.english_name}
                                      </option>
                                    ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="item_price"
                                    className="form-control"
                                    value={item.item_price}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="quantity"
                                    className="form-control"
                                    value={item.quantity}
                                    onChange={(e) => handleItemChange(index, e)}
                                    required
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="discount"
                                    className="form-control"
                                    value={item.discount}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="tax"
                                    className="form-control"
                                    value={item.tax}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="subtotal"
                                    className="form-control"
                                    value={item.subtotal}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    name="total"
                                    className="form-control"
                                    value={item.total}
                                    onChange={(e) => handleItemChange(index, e)}
                                  />
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    Remove
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td> Sub Total</td>
                              <td>
                                <input
                                  type="number"
                                  name="subtotal"
                                  className="form-control"
                                  value={returnItemsSubtotal}
                                  onChange={(e) =>
                                    setReturnItemsSubtotal(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td> Tax</td>
                              <td>
                                <input
                                  type="number"
                                  name="subtotal"
                                  className="form-control"
                                  value={returnItemsTax}
                                  onChange={(e) =>
                                    setReturnItemsTax(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                            <tr>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td style={{ border: "none" }}></td>
                              <td> Total</td>
                              <td>
                                <input
                                  type="number"
                                  name="subtotal"
                                  className="form-control"
                                  value={returnItemsTotal}
                                  onChange={(e) =>
                                    setReturnItemsTotal(e.target.value)
                                  }
                                />
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAddItem}
                      >
                        Add Item
                      </button>
                    </div>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Return Amount</th>
                            <th>Tax</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="number"
                                  name="discount"
                                  className="form-control"
                                  value={item.discount}
                                  onChange={(e) => handleItemChange(index, e)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="tax"
                                  className="form-control"
                                  value={item.tax}
                                  onChange={(e) => handleItemChange(index, e)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="total"
                                  className="form-control"
                                  value={item.total}
                                  onChange={(e) => handleItemChange(index, e)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td style={{ border: "none" }}></td>
                            <td> Sub Total</td>
                            <td>
                              <input
                                type="number"
                                name="returnSummarySubtotal"
                                className="form-control"
                                value={returnSummarySubtotal}
                                onChange={(e) =>
                                  setReturnSummarySubtotal(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "none" }}></td>
                            <td> Tax</td>
                            <td>
                              <input
                                type="number"
                                name="returnSummaryTax"
                                className="form-control"
                                value={returnSummaryTax}
                                onChange={(e) =>
                                  setReturnSummaryTax(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: "none" }}></td>
                            <td> Total</td>
                            <td>
                              <input
                                type="number"
                                name="returnSummaryTotal"
                                className="form-control"
                                value={returnSummaryTotal}
                                onChange={(e) =>
                                  setReturnSummaryTotal(e.target.value)
                                }
                              />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <button type="submit" className="btn btn-success">
                          Save Bill
                        </button>
                      </div>
                    </div>
                    {error && (
                      <div className="alert alert-danger mt-3">{error}</div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AddInvoiceCredit;
