import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axiosInstance from '../../utils/axiosInstance';

const AccountModal = ({ show, handleClose, account, mode }) => {
  const [formData, setFormData] = useState({
    account_code: '',
    account_name: '',
    account_type: '',
    account_subtype: '',
    description: '',
  });



  const storedUserId = localStorage.getItem('user_id');
  const storedCompanyId = localStorage.getItem('company_id');

  // Define account types and subtypes
  const ACCOUNT_TYPES = [
    { value: 'asset', label: 'Asset' },
    { value: 'liability', label: 'Liability' },
    { value: 'equity', label: 'Equity' },
    { value: 'revenue', label: 'Revenue' },
    { value: 'Cost of Goods Sold', label: 'Cost of Goods Sold' },
    { value: 'expenses', label: 'Expenses' }
  ];

  const ACCOUNT_SUBTYPES = {
    asset: [
      { value: 'current_asset', label: 'Current Asset' },
      { value: 'inventory_asset', label: 'Inventory Asset' },
      { value: 'non_current_asset', label: 'Non Current Asset' },
    ],
    liability: [
      { value: 'current_liability', label: 'Current Liability' },
      { value: 'long_term_liability', label: 'Long-term Liability' },
    ],
    equity: [
      { value: 'retained_earnings', label: 'Retained Earnings' },
      { value: 'share_capital', label: 'Share Capital' },
    ],
    revenue: [
      { value: 'sales', label: 'Sales Revenue' },
      { value: 'other', label: 'Other Revenue' },
    ],
    'Cost of Goods Sold': [
      { value: 'cost_of_goods_sold', label: 'Cost of Goods Sold' },
    ],
    expenses: [
      { value: 'ga_expenses', label: 'General and Administrative Expenses' },
      { value: 'payroll_expenses', label: 'Payroll Expenses' },
    ],
  };

  // Update formData when the account prop changes
  useEffect(() => {
    if (account) {
      setFormData({
        account_code: account.account_code || '',
        account_name: account.account_name || '',
        account_type: account.account_type || '',
        account_subtype: account.account_subtype || '',
        description: account.description || '',
      });
    }
  }, [account]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        if (mode === 'edit') {
            // Update the account via API
            await axiosInstance.put(`/api/accounts/${account.id}/`, {
                ...formData // Include the company ID in the request data
            }, {
                params: { user: storedUserId, company: storedCompanyId }
            });
        } else {
            // Create a new account (if needed)
            await axiosInstance.post('/api/accounts/', {
                ...formData,
                company: storedCompanyId
            }, {
                params: { user: storedUserId, company: storedCompanyId }
            });
        }
        handleClose(); // Close the modal after successful operation
    } catch (error) {
        console.error('Error saving account:', error);
    }
};

  // Render nothing if the account is not available
  if (!account && mode === 'edit') return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{mode === 'edit' ? 'Edit Account' : 'View Account'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {mode === 'view' ? (
          <div>
            <p><strong>Account Code:</strong> {formData.account_code}</p>
            <p><strong>Account Name:</strong> {formData.account_name}</p>
            <p><strong>Account Type:</strong> {formData.account_type}</p>
            <p><strong>Account Subtype:</strong> {formData.account_subtype}</p>
            <p><strong>Description:</strong> {formData.description}</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Account Code</Form.Label>
              <Form.Control
                type="text"
                name="account_code"
                value={formData.account_code}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account Name</Form.Label>
              <Form.Control
                type="text"
                name="account_name"
                value={formData.account_name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account Type</Form.Label>
              <Form.Control
                as="select"
                name="account_type"
                value={formData.account_type}
                onChange={handleChange}
                required
              >
                <option value="">Select Account Type</option>
                {ACCOUNT_TYPES.map((type) => (
                  <option key={type.value} value={type.value}>{type.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account Subtype</Form.Label>
              <Form.Control
                as="select"
                name="account_subtype"
                value={formData.account_subtype}
                onChange={handleChange}
                disabled={!formData.account_type}
              >
                <option value="">Select Account Subtype</option>
                {formData.account_type && ACCOUNT_SUBTYPES[formData.account_type]?.map((subtype) => (
                  <option key={subtype.value} value={subtype.value}>{subtype.label}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AccountModal;
