import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { Dropdown, Button, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CSVLink } from "react-csv";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import RawMaterialModal from "./RawMaterialModal"; // Assume you have a RawMaterialModal component

const ViewRawMaterials = () => {
  const [rawMaterials, setRawMaterials] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(10); // Pagination size
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // Modal mode
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    const companyId = localStorage.getItem("company_id");

    if (userId && companyId) {
      axiosInstance
        .get("/api/raw-materials/", {
          params: { user: userId, company: companyId },
        })
        .then((response) => setRawMaterials(response.data))
        .catch((error) => console.error("Error fetching raw materials", error));
    } else {
      console.error("User or company ID not found in localStorage");
    }
  }, []);

  const handleOpenModal = (material, editMode) => {
    setSelectedMaterial(material);
    setIsEditMode(editMode);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleSaveMaterial = (updatedMaterial) => {
    if (isEditMode) {
      // Update raw material via API
      axiosInstance
        .put(`/api/raw-materials/${updatedMaterial.id}/`, updatedMaterial)
        .then(() => {
          setRawMaterials(
            rawMaterials.map((mat) =>
              mat.id === updatedMaterial.id ? updatedMaterial : mat
            )
          );
          handleCloseModal();
        })
        .catch((error) => console.error("Error updating raw material", error));
    }
  };

  const handleAdd = () => {
    navigate("/add-raw-material");
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortedField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortedField(field);
    setSortDirection(direction);
  };

  const sortedMaterials = [...rawMaterials].sort((a, b) => {
    if (sortedField) {
      const comparison = a[sortedField] > b[sortedField] ? 1 : -1;
      return sortDirection === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const filteredMaterials = sortedMaterials.filter(
    (material) =>
      material.english_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      material.arabic_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      material.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedMaterials = filteredMaterials.slice(0, pageSize);

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Raw Materials</h1>
          <div>
            <Button variant="primary" onClick={handleAdd}>
              Add Raw Material
            </Button>
          </div>
        </div>

        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex align-items-center">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {pageSize}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPageSize(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(15)}>
                            15
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        data={rawMaterials}
                        className="btn btn-primary mx-3"
                        filename={"raw-materials.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>

                  {/* Raw Materials Table */}
                  <Table striped bordered hover responsive>
                    <thead style={{ textAlign: "center" }}>
                      <tr>
                        <th onClick={() => handleSort("code")}>
                          Code{" "}
                          {sortedField === "code" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("english_name")}>
                          English Name{" "}
                          {sortedField === "english_name" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("arabic_name")}>
                          Arabic Name{" "}
                          {sortedField === "arabic_name" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("unit")}>
                          Unit{" "}
                          {sortedField === "unit" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("opening_balance")}>
                          Opening Balance{" "}
                          {sortedField === "opening_balance" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th
                          onClick={() =>
                            handleSort("opening_balance_total_price")
                          }
                        >
                          Opening Balance Total Price{" "}
                          {sortedField === "opening_balance_total_price" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("minimum_quantity")}>
                          Minimum Quantity{" "}
                          {sortedField === "minimum_quantity" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {paginatedMaterials.map((material) => (
                        <tr key={material.id}>
                          <td>{material.code}</td>
                          <td>{material.english_name}</td>
                          <td>{material.arabic_name}</td>
                          <td>{material.unit}</td>
                          <td>{material.opening_balance}</td>
                          <td>{material.opening_balance_total_price}</td>
                          <td>{material.minimum_quantity}</td>
                          <td>
                            <Button
                              variant="info"
                              size="sm"
                              onClick={() => handleOpenModal(material, false)}
                            >
                              View
                            </Button>
                            <Button
                              variant="primary"
                              size="sm"
                              className="mx-2"
                              onClick={() => handleOpenModal(material, true)}
                            >
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {filteredMaterials.length === 0 && (
                    <p className="text-center">No raw materials available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Raw Material Modal */}
      <RawMaterialModal
        show={isModalVisible}
        handleClose={handleCloseModal}
        rawMaterialData={selectedMaterial} // Updated prop name
        isEditMode={isEditMode}
        onSave={handleSaveMaterial}
      />
    </div>
  );
};

export default ViewRawMaterials;
