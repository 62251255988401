import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { Dropdown, Button, Table } from "react-bootstrap";
import { CSVLink } from "react-csv";
import UserSideBar from "../../master-layout/UserSideBar";
import Header from "../../master-layout/Header";
import ProductServiceModal from "./ProductServiceModal";
import EditBtn from "../../ui-kit/TableActionBtns/EditBtn.jsx";
import ViewBtn from "../../ui-kit/TableActionBtns/ViewBtn.jsx";

const ViewProductsServices = () => {
  const [productsServices, setProductsServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pageSize, setPageSize] = useState(10); // Pagination size
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedProductService, setSelectedProductService] = useState(null);
  const navigate = useNavigate();

  const storedUserId = localStorage.getItem("user_id");
  const storedCompanyId = localStorage.getItem("company_id");

  useEffect(() => {
    if (storedUserId && storedCompanyId) {
      axiosInstance
        .get("/api/products-services/", {
          params: { user: storedUserId, company: storedCompanyId },
        })
        .then((response) => setProductsServices(response.data))
        .catch((error) =>
          console.error("Error fetching products/services", error)
        );
    } else {
      console.error("User or company ID not found in localStorage");
    }
  }, [storedUserId, storedCompanyId]);

  const handleView = (id) => {
    const productService = productsServices.find((item) => item.id === id);
    setSelectedProductService(productService);
    setIsEditMode(false); // For view mode
    setShowModal(true);
  };

  const handleUpdate = (updatedProductService) => {
    axiosInstance
      .put(
        `/api/products-services/${updatedProductService.id}/`,
        updatedProductService,
        {
          params: { user: storedUserId, company: storedCompanyId },
        }
      )
      .then(() => {
        setProductsServices(
          productsServices.map((item) =>
            item.id === updatedProductService.id ? updatedProductService : item
          )
        );
        handleModalClose();
      })
      .catch((error) => console.error("Error updating product/service", error));
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedProductService(null);
  };

  const handleAdd = () => {
    navigate("/addps");
  };

  const handleSort = (field) => {
    let direction = "asc";
    if (sortedField === field && sortDirection === "asc") {
      direction = "desc";
    }
    setSortedField(field);
    setSortDirection(direction);
  };

  const sortedProductsServices = [...productsServices].sort((a, b) => {
    if (sortedField) {
      const comparison = a[sortedField] > b[sortedField] ? 1 : -1;
      return sortDirection === "asc" ? comparison : -comparison;
    }
    return 0;
  });

  const filteredProductsServices = sortedProductsServices.filter(
    (item) =>
      item.english_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedProductsServices = filteredProductsServices.slice(0, pageSize);

  return (
    <div>
      <Header />
      <UserSideBar />
      <main id="main" className="main">
        <div className="pagetitle d-flex justify-content-between align-items-center">
          <h1>Products & Services</h1>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* Top Controls: Rows per Page, Export, Search, Add Button */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                      {/* Rows per Page Dropdown */}
                      <Dropdown>
                        <Dropdown.Toggle variant="secondary">
                          Rows : {pageSize}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPageSize(5)}>
                            5
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(10)}>
                            10
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => setPageSize(20)}>
                            20
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                      {/* Export CSV Button */}
                      <CSVLink
                        data={productsServices}
                        className="btn btn-primary mx-3"
                        filename={"products-services.csv"}
                        style={{ width: "36%" }}
                      >
                        Export CSV
                      </CSVLink>

                      {/* Search Input */}
                      <input
                        type="text"
                        className="form-control w-50"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>

                    {/* Add Product/Service Button */}
                    <Button
                      variant="primary"
                      onClick={handleAdd}
                      className="float-right"
                    >
                      Add Product/Service
                    </Button>
                  </div>

                  {/* Table */}
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th onClick={() => handleSort("code")}>
                          Code{" "}
                          {sortedField === "code" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("english_name")}>
                          Name{" "}
                          {sortedField === "english_name" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("type")}>
                          Type{" "}
                          {sortedField === "type" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th onClick={() => handleSort("status")}>
                          Status{" "}
                          {sortedField === "status" &&
                            (sortDirection === "asc" ? "↑" : "↓")}
                        </th>
                        <th style={{ width: "15%" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody style={{ textAlign: "center" }}>
                      {paginatedProductsServices.map((item) => (
                        <tr key={item.id}>
                          <td>{item.code}</td>
                          <td>{item.english_name}</td>
                          <td>{item.type}</td>
                          <td>{item.status ? "Active" : "Inactive"}</td>
                          <td>
                            <ViewBtn onClick={() => handleView(item.id)} />
                            <EditBtn onClick={() => handleView(item.id)} />
                            {/* <Button
                              variant="info"
                              onClick={() => handleView(item.id)}
                              className="me-2"
                            >
                              View
                            </Button>
                            <Button
                              variant="warning"
                              onClick={() => handleView(item.id)}
                            >
                              Edit
                            </Button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* ProductServiceModal */}
      {showModal && (
        <ProductServiceModal
          show={showModal}
          handleClose={handleModalClose}
          productServiceData={selectedProductService}
          isEditMode={isEditMode}
          onSave={handleUpdate}
        />
      )}
    </div>
  );
};

export default ViewProductsServices;
