// axiosInstance.js
import axios from "axios";
// for build baseURL
// https://server.toimitek.cloud
const axiosInstance = axios.create({
  baseURL: "https://server.toimitek.cloud", // https://server.toimitek.cloud Adjust to your Django server URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the token in request headers
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
