import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../assets/css/style.css";

const Sidebar = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation(); // Access the translation hook

  const isActive = (path) => location.pathname === path;

  const isParentActive = (paths) => paths.some((path) => isActive(path));

  // Determine if the current language is RTL
  const isRtl = i18n.language === "ar";

  return (
    <aside id="sidebar" className={`sidebar ${isRtl ? "rtl" : "ltr"}`}>
      <ul className="sidebar-nav" id="sidebar-nav">
        {/* Dashboard Nav */}
        <li className={`nav-item ${isActive("/dashboard") ? "active" : ""}`}>
          <a className="nav-link collapsed" href="/dashboard">
            <i className="bi bi-house"></i>
            <span>{t("sidebar.dashboard")}</span>
          </a>
        </li>
        {/* End Dashboard Nav */}
        {/* Catalog Nav */}
        <li
          className={`nav-item ${
            isParentActive(["/viewCategories", "/viewps"]) ? "active" : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#catalog-nav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.catalog")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="catalog-nav"
            className={`nav-content collapse ${
              isParentActive(["/viewCategories", "/viewps"]) ? "show" : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/viewCategories"
                className={isActive("/viewCategories") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.category")}</span>
              </a>
            </li>
            <li>
              <a href="/viewps" className={isActive("/viewps") ? "active" : ""}>
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.productServices")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End Catalog Nav */}

        {/* Clients & Suppliers */}
        <li
          className={`nav-item ${
            isActive("/viewClientAndSupplier") ? "active" : ""
          }`}
        >
          <a className="nav-link collapsed" href="/viewClientAndSupplier">
            <i className="bi bi-people"></i>
            <span>{t("sidebar.clientsSuppliers")}</span>
          </a>
        </li>

        {/* Inventory Nav */}
        <li
          className={`nav-item ${
            isParentActive([
              "/inventory",
              "/WarehouseView",
              "/viewRowMaterials",
            ])
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#Inventorynav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.inventory")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="Inventorynav"
            className={`nav-content collapse ${
              isParentActive([
                "/inventory",
                "/WarehouseView",
                "/viewRowMaterials",
              ])
                ? "show"
                : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/inventory"
                className={isActive("/inventory") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.inventory")}</span>
              </a>
            </li>
            <li>
              <a
                href="/WarehouseView"
                className={isActive("/WarehouseView") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.warehouse")}</span>
              </a>
            </li>
            <li>
              <a
                href="/viewRowMaterials"
                className={isActive("/viewRowMaterials") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.stockTransfer")}</span>
              </a>
            </li>
            <li>
              <a
                href="/viewRowMaterials"
                className={isActive("/viewRowMaterials") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.rowMaterials")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End Inventory Nav */}

        {/* Accounting Nav */}
        <li
          className={`nav-item ${
            isParentActive([
              "/chartOfAccounts",
              "/bank-account",
              "/transfer-account",
              "/account-payable",
              "/AccountsReceivableList",
            ])
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#Accountingnav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.accounting")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="Accountingnav"
            className={`nav-content collapse ${
              isParentActive([
                "/chartOfAccounts",
                "/bank-account",
                "/transfer-account",
                "/account-payable",
                "/AccountsReceivableList",
              ])
                ? "show"
                : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/chartOfAccounts"
                className={isActive("/chartOfAccounts") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.chartOfAccounts")}</span>
              </a>
            </li>
            <li>
              <a
                href="/bank-account"
                className={isActive("/bank-account") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.bankAccounts")}</span>
              </a>
            </li>
            <li>
              <a
                href="/transfer-account"
                className={isActive("/transfer-account") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.bankAccountTransfer")}</span>
              </a>
            </li>
            <li>
              <a
                href="/account-payable"
                className={isActive("/account-payable") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.accountPayable")}</span>
              </a>
            </li>
            <li>
              <a
                href="/AccountsReceivableList"
                className={isActive("/AccountsReceivableList") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.accountReceivable")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End Accounting Nav */}

        {/* Purchases Nav */}
        <li
          className={`nav-item ${
            isParentActive([
              "/billsDebitNotes",
              "/bills",
              "/purchases",
              "/billsCreditNotes",
            ])
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#purchase-nav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.purchases")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="purchase-nav"
            className={`nav-content collapse ${
              isParentActive([
                "/billsDebitNotes",
                "/bills",
                "/purchases",
                "/billsCreditNotes",
              ])
                ? "show"
                : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/purchases"
                className={isActive("/purchases") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.purchaseOrders")}</span>
              </a>
            </li>
            <li>
              <a href="/bills" className={isActive("/bills") ? "active" : ""}>
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.bills")}</span>
              </a>
            </li>
            <li>
              <a
                href="/billsDebitNotes"
                className={isActive("/billsDebitNotes") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.billDebitNotes")}</span>
              </a>
            </li>
            <li>
              <a
                href="/billsCreditNotes"
                className={isActive("/billsCreditNotes") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.billCreditNotes")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End Purchases Nav */}

        {/* POS Nav */}
        <li
          className={`nav-item ${
            isParentActive(["/POSOrders", "/POSInvoices"]) ? "active" : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#pos-nav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.pos")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="pos-nav"
            className={`nav-content collapse ${
              isParentActive(["/POSOrders", "/POSInvoices"]) ? "show" : ""
            }`}
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/POSOrders"
                className={isActive("/POSOrders") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.posOrders")}</span>
              </a>
            </li>
            <li>
              <a
                href="/POSInvoices"
                className={isActive("/POSInvoices") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.posInvoices")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End POS Nav */}
        {/* Sales Nav */}
        <li
          className={`nav-item ${
            isParentActive([
              "/Invoices",
              "/ViewInvoicesDebit",
              "/viewInvoicesCredit",
            ])
              ? "active"
              : ""
          }`}
        >
          <a
            className="nav-link collapsed"
            data-bs-toggle="collapse"
            href="#sales-nav"
          >
            <i className="bi bi-box"></i>
            <span>{t("sidebar.sales")}</span>
            <i
              className={`bi bi-chevron-${isRtl ? "left" : "down"} ms-auto`}
            ></i>
          </a>
          <ul
            id="sales-nav"
            className={`nav-content collapse ${
              isParentActive([
                "/Invoices",
                "/ViewInvoicesDebit",
                "/viewInvoicesCredit",
              ])
                ? "show"
                : ""
            }`} // Ensure parent stays open if any child is active
            data-bs-parent="#sidebar-nav"
          >
            <li>
              <a
                href="/Invoices"
                className={isActive("/Invoices") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.invoices")}</span>
              </a>
            </li>
            <li>
              <a
                href="/ViewInvoicesDebit"
                className={isActive("/ViewInvoicesDebit") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.invoiceDebitNotes")}</span>
              </a>
            </li>
            <li>
              <a
                href="/viewInvoicesCredit"
                className={isActive("/viewInvoicesCredit") ? "active" : ""}
              >
                <i className="bi bi-circle"></i>
                <span>{t("sidebar.invoiceCreditNotes")}</span>
              </a>
            </li>
          </ul>
        </li>
        {/* End Sales Nav */}
      </ul>
    </aside>
  );
};

export default Sidebar;
